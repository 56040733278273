import { useEffect } from "react";

const WebProb = () => {
    useEffect(() => {
        document.title = "ＮＴＴビジネスソリューションズの電子認証サービスe-Probatio 申込書作成ツールぷろばてぃお君";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                ""
            );
    }, [])
    return (
        <>
            <h2>ＮＴＴビジネスソリューションズの電子認証サービスe-Probatio 申込書作成ツールぷろばてぃお君</h2>
        </>
    );
}

export default WebProb;