import React from "react";
import {Link} from "react-router-dom";

export type LinkData = {
    [index: string]: LinkCategory;
};

export type LinkCategory = {
    category: string;
    title: string;
    description: string;
    links: Link[];
};

export type Link = {
    label: string;
    url: string;
};

const Links = (props: { link: LinkCategory }) => {
    return props.link
        ? <>
            <h2 className="ttl">{props.link.title}</h2>
            {props.link.description.length > 0 &&
            <p className="txt">{props.link.description}</p>}
            <ul>
                {props.link.links.map((link: Link, index: number) =>
                    index > 0 && link.label === "" ?
                        <></>
                        :
                        <li key={index.toString()}>
                            <a href={link.url}  
                            className= "e_btn_arw"
                            target={link.url  === 'https://www.e-probatio.com/web-prob/'? "blank": "" }>{link.label}</a>
                        </li>
                )}
            </ul>
        </>
        : <></>;
};

export default Links;
