import { Link } from "react-router-dom";
import NavGroup from "./NavGroup";
import NavItem from "./NavItem";
import { 
    aboutNavSubItems,
    queryNavSubItems, 
    priceNavSubItems, 
    supportNavSubItems, 
    faqNavSubItems
} from "./nav_items";

/**
 * ヘッダーメニューのコンポーネント
 * @returns Header
 */
const Header = () => {

    return (
        <>
            <header className="site-header">
                <div className="site-header__inner">
                    <div className="header__logo">
                        <Link to="/">
                            <img src="/assets/images/common/logo.png" alt="sample" />
                        </Link>
                    </div>
                    <div className="header__gnavi">
                        <p className="header__gnavi__msg sp_large">e-Probatioに関する各種お問い合わせはこちらから</p>
                        <p className="contactBtn"><a href="/contact/">お問い合わせ</a></p>
                        <nav>
                            <ul className="menu">
                                <NavItem 
                                    title="トップページ"
                                    url="/"
                                    isSp={false}
                                    className="sp_large"
                                />
                                <NavGroup 
                                    title="e-Probatio PS2について"
                                    url="/about"
                                    navSubItems={aboutNavSubItems}
                                    element="header"
                                />
                                <NavGroup 
                                    title="お申し込みのご案内"
                                    url="/query"
                                    navSubItems={queryNavSubItems}
                                    element="header"
                                />
                                <NavGroup 
                                    title="価格について"
                                    url="/price"
                                    navSubItems={priceNavSubItems}
                                    element="header"
                                />
                                <NavGroup 
                                    title="サポート情報"
                                    url="/support"
                                    navSubItems={supportNavSubItems}
                                    element="header"
                                />
                                <NavGroup 
                                    title="よくあるご質問"
                                    url="/faq"
                                    navSubItems={faqNavSubItems}
                                    element="header"
                                />
                                <NavItem 
                                    title="新着情報"
                                    url="/news"
                                    isSp={true}
                                />
                                <NavItem 
                                    title="公開情報"
                                    url="/footer/info"
                                    isSp={false}
                                    className="no-arrow"
                                />
                            </ul>
                        </nav>
                    </div>
                    <div id="hamburger_button"><span></span>MENU</div>
                </div>
                <div className="overlay pc_large"></div>
            </header>
        </>
    );
};

export default Header;
