export type NavItemProps = {
    title: string;
    url: string;
    isSp: boolean;
    className?: string;
    target?: string;
}

/**
 * 単一のナビアイテム
 * @param props
 * @returns 
 */
const NavItem = (props: NavItemProps) => {
    const { title, url, isSp, className, target } = props;
    return (
        <>
            <li className={isSp ? "sp_large" : ""}>
                <a href={url} className={className} target={target ?? ""}>{title}</a>
            </li>
        </>
    )
}

export default NavItem;
