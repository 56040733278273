import { useEffect } from 'react';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import CTAButton from '../../components/common/CTAButton';
import EyeCatch from '../../components/common/EyeCatch';
import Body from '../../components/layout/Body';

const Query = () => {
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/query", breadcrumb: "お申し込みのご案内"},
    ]
    useEffect(() => {
        document.title = "お申し込みのご案内 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );
    }, [])
    return (
    <>
        <Body bodyClass="query">
            <EyeCatch
                title="お申し込みのご案内"
                description="e-Probatio PS2
                電子証明書（ICカード）のお申し込みについてのご案内となります。"
                imgPath="/assets/images/query/h1_query.png"
            />
            <div className="page-content">
                <div className="query__block query__intro">
                    <div className="inner">
                        <p>新規・継続・追加・再発行・失効のお申し込みについてのご案内となります。</p>
                    </div>
                </div>

                <div className="query__block query__pages">
                    <div className="inner">

                        <div className="flexBox">

                            <div className="flexItem">
                                <div className="query__item">
                                    <figure><img src="../assets/images/query/index/query_img01.png" alt="" /></figure>
                                    <div className="txtarea">
                                        <h3 className="ttl">新規のお申し込み</h3>
                                        <p className="txt">初めてICカードをお申し込みにされる場合のご案内となります。</p>
                                        <p className="link">
                                            <a href="./query/first" className="e_btn">新規のお申し込みを見る</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flexItem">
                                <div className="query__item">
                                    <figure><img src="../assets/images/query/index/query_img02.png" alt="" /></figure>
                                    <div className="txtarea">
                                        <h3 className="ttl">継続のお申し込み</h3>
                                        <p className="txt">現在お持ちのICカードの有効期間満了に伴って、継続のお申し込みされる場合のご案内となります。</p>
                                        <p className="link">
                                            <a href="./query/continue" className="e_btn">継続のお申し込みを見る</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flexItem">
                                <div className="query__item">
                                    <figure><img src="../assets/images/query/index/query_img03.png" alt="" /></figure>
                                    <div className="txtarea">
                                        <h3 className="ttl">追加のお申し込み</h3>
                                        <p className="txt">すでにお持ちのICカードを追加でお申し込みされる場合のご案内となります。</p><br />
                                        <p className="link">
                                            <a href="./query/appnd" className="e_btn">追加のお申し込みを見る</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flexItem">
                                <div className="query__item">
                                    <figure><img src="../assets/images/query/index/query_img04.png" alt="" /></figure>
                                    <div className="txtarea">
                                        <h3 className="ttl">再発行のお申し込み</h3>
                                        <p className="txt">登録情報に変更があった、ICカードを紛失した等で、改めて新しいICカードをお申し込みされる場合のご案内となります。</p>
                                        <p className="link">
                                            <a href="./query/reissue" className="e_btn">再発行のお申し込みを見る</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flexItem">
                                <div className="query__item">
                                    <figure><img src="../assets/images/query/index/query_img05.png" alt="" /></figure>
                                    <div className="txtarea">
                                        <h3 className="ttl">失効のお申し込み</h3>
                                        <p className="txt">現在お持ちのICカードを利用できない状態にするための手続きとなります。</p>
                                        <p className="link">
                                            <a href="./query/invalidation" className="e_btn">失効のお申し込み</a>
                                        </p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>

            <CTAButton
                leftBtn={{link: "../query/first", text: "新規申込方法"}}
                centerBtn={{link: "../price", text: "価格について"}}
                rightBtn={{link: "../faq", text: "よくあるご質問"}}
            />
            <Breadcrumbs routes={routes} />
        </Body>
    </>
    );
}

export default Query;