import CampaignBlock from "./CampaignBlock";

export type CampaignMessage = {
    description: string
    body: BlockItem[];
}

export type BlockItem = {
    text: string;
    image: string;
    caption: string;
    alt: string;
}


const CampaignGroup = (props: {campaign: CampaignMessage}) => {
    const { campaign } = props;
    return (
        <>
            <div className="campaign__leadTxt">
                <div className="inner">
                    <p>{campaign.description}</p>
                </div>
            </div>
            <CampaignBlock body={campaign.body}/>
        </>
    );
}

export default CampaignGroup;