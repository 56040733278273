import axios from "axios";
import React, { useEffect, useState } from "react";
import { findNewsCategory, NewsSorter } from "../news/NewsList";
import { News, NewsData } from "../news/types";

const ImportantAnnouncements = () => {
  const [importantNewsList, setImportantNewsList] = useState<NewsData>({all: [], important_announcement: [], topics: [], maintenance: [], supported_system_information:[]});
  const [newsList, setNewsList] = useState<NewsData>({all: [], important_announcement: [], topics: [], maintenance: [], supported_system_information:[]});
  const [isImportantNewsLoaded, setIsImportantNewsLoaded] = useState<boolean>(false);
  const [isNewsLoaded, setIsNewsLoaded] = useState<boolean>(false);
  useEffect(() => {
    axios.get("/cms/json/news.json")
            .then(response => response.data)
            .then((data: NewsData) => {
                data.all = [];
                data.important_announcement = data.important_announcement
                  .sort(NewsSorter)
                  .slice(0, Math.min(2, data.important_announcement.length));
                setImportantNewsList(data);
                setIsImportantNewsLoaded(true);
            });
  }, []);
  useEffect(() => {
    axios.get("/cms/json/news/data.json")
            .then(response => response.data)
            .then((data: NewsData) => {
                setNewsList(data);
                setIsNewsLoaded(true);
            });
  }, []);

  return (importantNewsList.important_announcement.length > 0 && isImportantNewsLoaded && isNewsLoaded) ? (
    <>
      <h2 className="ttl">重要なお知らせ</h2>
      <div className="list">
        <ul>
          {importantNewsList.important_announcement.map((news: News) => {
            const category = findNewsCategory(news, newsList);
            if (category) {
              const publish_date = new Date(news.publish_date);
              return <li key={news.id.toString()}>
              <span className="date">{`${publish_date.getFullYear()}/${publish_date.getMonth()+1}/${publish_date.getDate()}`}</span>
              <a href={news.format === 'リンク' ? news.link : `${category.link}/${news.id}`}>{news.title}</a>
              </li>
              }
          })}
        </ul>
      </div>
    </>
  ) : <></>;
}
export default ImportantAnnouncements;
