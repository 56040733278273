import React from "react";
import {Answer} from "./QuestionTab";
import {File} from "../common/types";
import AttachedFile from "../common/File";

const Answers = (props: { answers: Answer[], file: File, questionIndex: Number }) => {
    const answers = props.answers.filter((answer: Answer) => answer.answer !== "");
    return (
        <dd className={props.questionIndex ? "" : "openAtFirstTimeOnly"}>
            {
                answers.map((answer: Answer, index: Number) => {
                    return (
                        <React.Fragment key={index.toString()}>
                            <span dangerouslySetInnerHTML={{__html: (answer.answer)}}/>
                            {index < answers.length - 1 ? <br /> : <></>}
                            {answer.image.length
                                ? (<div className="faq__item__cont">
                                    <div className="box">
                                        <p>{answer.caption}</p>
                                        <a href={answer.image} className="colorbox js-modal-img">
                                            <img src={answer.image} alt={answer.alt}/>
                                        </a>
                                    </div>
                                </div>)
                                : ""
                            }
                        </React.Fragment>
                    )
                })
            }
            <AttachedFile file={props.file}/>
        </dd>
    );
}

export default Answers;
