import {useEffect, useState} from "react";
import axios from "axios";
import QuestionTab, {
    Question,
    QuestionCategories,
    QuestionRow,
    ExclusionCategoryNames} from "../../components/faq/QuestionTab";
import Body from "../../components/layout/Body";
import EyeCatch from "../../components/common/EyeCatch";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import useHashLink from "../../hooks/useHashLink";
import { useLocation } from "react-router-dom";


const FAQ = () => {
	useHashLink();
    const routes = [
        {path: "/", breadcrumb: "e-Probatio トップ"},
        {path: "/faq", breadcrumb: "よくあるご質問"},
    ];

	const location = useLocation();
	useEffect(() => {
		seachAndOpen(location.hash);
	}, [location.hash])

    const [questions, setQuestions] = useState<Question>({})
    useEffect(() => {
        axios.get("/cms/json/faq/data.json")
            .then(response => response.data)
            .then((data: Question) => {
                ExclusionCategoryNames.forEach((exclusionCategoryName: string) =>
                    Object.keys(data).forEach((categoryName: string) =>
                        delete data[categoryName][exclusionCategoryName]
                    )
                );
                const now = new Date();
                Object.values(data).forEach((questionCategories: QuestionCategories) =>
                    Object.keys(questionCategories).forEach((subCategoryName: string) => {
                        questionCategories[subCategoryName] = questionCategories[subCategoryName]
                            .filter((row: QuestionRow) => row.service.includes("PS2")
                                && (new Date(row.publish_date)) <= now)
                            .sort((a: QuestionRow, b: QuestionRow) => {
                                if (a.priority.length > 0 && b.priority.length > 0) {
                                    return Number(a.priority) < Number(b.priority) ? 1 : -1;
                                } else if (a.priority.length === 0 && b.priority.length === 0) {
                                    return a.question > b.question ? 1 : -1;
                                }
                                return b.priority.length > 0 ? 1 : -1;
                            });
                    })
                );
                setQuestions(data)
            });
    }, []);

    const getCategoryId = (categoryIndex: number) => {
        return `faq${("0" + (categoryIndex + 1).toString()).slice(0, 2)}`;
    };

    const getTopQuestionAnchor = (categoryIndex: number, questionRows: QuestionRow[]) => {
        if (questionRows.length === 0) {
            return "";
        }
        return getQuestionAnchor(categoryIndex, questionRows[0]);
    };

    const getQuestionAnchor = (categoryIndex: number, questionRow: QuestionRow) => {
        return `faq${(categoryIndex + 1).toString()}_${questionRow.id}`;
    };

    useEffect(() => {
        document.title = "よくあるご質問 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );
    }, [])

    return (
        <>
            <Body bodyClass="faq">
                <EyeCatch title="よくあるご質問" />
                {Object.keys(questions).map((category: string, categoryIndex: number) =>
                    <div {...({id: `faq__anchor_${categoryIndex === 0 ? "a" : "b" }`})}
                         className={[
                             "faq__anchor__block",
                             "achrBlock0" + (categoryIndex + 1).toString(),
                         ].join(" ")}
                         key={categoryIndex.toString()}>
                        <div className="inner">
                            <h2 className="faq__anchor__title" dangerouslySetInnerHTML={{ __html: category }} />
                            <ul className="faq__anchor__list">
                                {Object.keys(questions[category]).map((subCategory: string) =>
                                    <li>
                                        <span>
                                            <a href={"#" + getTopQuestionAnchor(categoryIndex, questions[category][subCategory])}
                                               className={"e_btn_anchor " + (categoryIndex ? "type2" : "")}>
                                                {subCategory}
                                            </a>
                                        </span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                )}
                {Object.keys(questions).map((category: string, categoryIndex: number) =>
                    <div id={getCategoryId(categoryIndex)}
                         className={[
                             "faq__cont__block",
                             "contBlock0" + (categoryIndex + 1).toString(),
                         ].join(" ")}
                         key={categoryIndex.toString()}>
                        <div className="inner">
                            <h2 className="faq__cont__title" dangerouslySetInnerHTML={{ __html: category }} />
                            {Object.keys(questions[category]).map((subCategory: string, subCategoryIndex: number) =>
                                <div key={subCategoryIndex.toString()}>
                                    <h3 className="faq__item__title">{subCategory}</h3>
                                    {questions[category][subCategory].map((question: QuestionRow, questionIndex: number) =>
                                        <dl className="faq__item" id={getQuestionAnchor(categoryIndex, question)}>
                                            <QuestionTab question={question}
                                                        key={questionIndex.toString()}
                                                        questionIndex={questionIndex}/>
                                        </dl>
                                    )}
                                    <p className="faq__anchor__backbtn"><a href={`#faq__anchor_${categoryIndex === 0 ? "a" : "b"}`} className="back">{categoryIndex === 0 ? "e-Probatio PS2 お申し込みに関するご質問に戻る" : "e-Probatio PS2 セットアップ・システムに関するご質問に戻る"}</a></p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className="modal-wrap"><div className="modal-inner"><div className="modal-image"><span className="js-modal-close"></span></div></div></div>
                <Breadcrumbs routes={routes}/>
            </Body>
        </>
    );
}

export default FAQ;


/**
 * ページレンダリング直後は hash で指定された要素が存在しない場合があるので、100ms ごとに 30 回まで検索し、見つけたらその要素を開く
 */
const seachAndOpen = (hash: string, n = 0) => {
    if (n > 30) return;

    if (!hash.length) return;

    clearTimeout(timeout);
    timeout = setTimeout(() => {
        const element = document.body.querySelector(hash);

        if(!element) {
            seachAndOpen(hash, n + 1);
            return;
        };

        if(element.nodeName !== 'DL') return;

        const clickTarget = element.querySelector('dt');

        if(!clickTarget) {
            seachAndOpen(hash, n + 1);
            return;
        }

        if(clickTarget.classList.contains('open')) return;

        clickTarget.click();
        seachAndOpen(hash, n + 1);
    }, 100);
}
let timeout: NodeJS.Timeout;
