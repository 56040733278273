import axios from "axios";
import React, {useEffect, useState} from "react";
import QuestionTab, {Question, QuestionRow, ExclusionCategoryNames} from "../faq/QuestionTab";

const FAQ = () => {
    const [questionRows, setQuestionRows] = useState<QuestionRow[]>([]);
    useEffect(() => {
        axios.get("/cms/json/faq.json")
            .then(response => response.data)
            .then((data: Question) => {
                ExclusionCategoryNames.forEach((exclusionCategoryName: string) =>
                    Object.keys(data).forEach((categoryName: string) =>
                        delete data[categoryName][exclusionCategoryName]
                    )
                );
                const now = new Date();
                const newQuestionRows: QuestionRow[] =
                    Object.keys(data).flatMap((category: string) =>
                        Object.keys(data[category])
                            .filter((subCategory: string) => !ExclusionCategoryNames.includes(subCategory))
                            .flatMap((subCategory: string) =>
                                data[category][subCategory].filter((question: QuestionRow) =>
                                    question.show_on_top
                                    && question.service.includes("PS2")
                                    && (new Date(question.publish_date)) <= now
                                )
                            )
                    ).sort((a: QuestionRow, b: QuestionRow) =>
                        (new Date(a.modified_date)) < (new Date(b.modified_date)) ? 1 : -1
                    ).slice(0, 5);
                setQuestionRows(newQuestionRows);
            });
    }, []);

    return (
        <>
            <h2 className="ttl">よくあるご質問</h2>
            {questionRows.map((question: QuestionRow, questionIndex: number) =>
                <dl className="faqItem" key={questionIndex.toString()}>
                    <QuestionTab question={question} questionIndex={questionIndex}/>
                </dl>
            )}
            <div className="link">
                <a href="/faq" className="e_btn type01">ほかのよくあるご質問</a>
            </div>
            <div className="modal-wrap"><div className="modal-inner"><div className="modal-image"><span className="js-modal-close"></span></div></div></div>
        </>
    )
}
export default FAQ;