import { useEffect } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import EyeCatch from "../../components/common/EyeCatch";
import Body from "../../components/layout/Body";

const ICReader = () => {
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/support", breadcrumb: "サポート情報"},
        { path: "/support/icreader", breadcrumb: "ICカードリーダーについて"},
    ]
    useEffect(() => {
        document.title = "ICカードリーダーについて | サポート情報 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "初めてICカードをご利用のお客さまは、ICカードリーダーを必ずご購入ください。ご購入後6ヶ月以内の故障は、無償で交換または修理（ただし、お客さまの使用に起因する故障は除きます）。"
            );
    }, [])
    return (
    <>
        <Body bodyClass="icreader">
            <EyeCatch
                title="ICカードリーダーについて"
                description="動作確認済みICカードリーダーとソフトウェアのご案内となります。"
            />
                <div className="page-content">

                    <div className="icreader__block intro">
                        <div className="inner">
                            <p className="leadTxt">e-Probatio PS2では、以下の各サービスを提供しています。<br />
                                電子入札などでは、電子証明書が必須となります。また初めてご利用いただく方は、ICカードリーダーも必要になります。 <br />
                                パソコンへのソフトウェアのインストールおよびICカードの機能検証などを行うセットアップサービス（有料）もご用意しております。<br />
                                {"インターネットに接続できる環境とパソコン（Windows)はお客さまでご用意ください。"}
                            </p>
                        </div>
                    </div>

                    <div className="anchor icreader__anchor">
                        <ul>
                            <li><a href="#s01" className="e_btn_anchor">動作確認済みICカードリーダー</a></li>
                            <li><a href="#s02" className="e_btn_anchor">ソフトウェアダウンロード</a></li>
                        </ul>
                    </div>

                    <div id="s01" className="icreader__block icreader01">
                        <div className="inner">
                            <h2 className="icreader__block__ttl">動作確認済みICカードリーダー</h2>
                            <div className="icreader__cont cont_1">
                                <table className="default tbl_1">
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>メーカー</th>
                                            <th rowSpan={2}>機種</th>
                                            <th rowSpan={2}>タイプ</th>
                                            <th colSpan={2}>対応OS（Windows）</th>
                                        </tr>
                                        <tr>
                                            <th>10</th>
                                            <th>11</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Identive</th>
                                            <td>CLOUD 2700 R</td>
                                            <td>USB</td>
                                            <td>○<sup>※1</sup></td>
                                            <td>○<sup>※1</sup></td>
                                        </tr>
                                        <tr>
                                            <th>SCM<br className="sp" />(Identive)</th>
                                            <td>SCR3310 (販売終了)</td>
                                            <td>USB</td>
                                            <td>○<sup>※2</sup></td>
                                            <td>○<sup>※2</sup></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="note count">
                                    <ul>
                                        <li>ドライバーバージョンなどの条件により、デバイスマネージャーやプログラムと機能の一覧で「uTrust」からはじまる表記になることがあります。</li>
                                        <li>弊社独自の検証にて動作確認しました。</li>
                                    </ul>
                                </div>
                                <div className="notice">
                                    <h4>ご注意</h4>
                                    <ul>
                                        <li>ICカードリーダーは接触型のみ利用可能です。（非接触型は非対応）</li>
                                        <li>ICカードリーダーを接続するために、パソコンに利用可能なUSB-Aポートが1つ必要です。</li>
                                        <li>パソコン本体のUSB-AポートにICカードリーダーを直接接続してください。</li>
                                    </ul>
                                </div>
                                <div className="note">
                                    <ul>
                                        <li>Windows は米国 Microsoft Corporation の米国およびその他の国における商標または登録商標です。</li>
                                        <li>記載されている社名および商品名などは各社の商標または登録商標です。</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="s02" className="icreader__block icreader02">
                        <div className="inner">
                            <h2 className="icreader__block__ttl">ソフトウェアダウンロード</h2>
                            <div className="icreader__cont cont_1">
                                <table className="default tbl_1">
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>メーカー</th>
                                            <th rowSpan={2}>機種</th>
                                            <th rowSpan={2}>ドライバー</th>
                                            <th rowSpan={2}>マニュアル</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Identive</th>
                                            <td>CLOUD 2700 R</td>
                                            <td><a href="https://support.e-probatio.com/soft/rwdriver/Identiv_uTrust_V1.27.exe" download>ダウンロード(4.06MB)</a></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>SCM<br className="sp" />(Identive)</th>
                                            <td>SCR3310</td>
                                            <td><a href="https://support.e-probatio.com/soft/rwdriver/SCR3xxx_V8.51.exe" download>ダウンロード(6.77MB)</a></td>
                                            <td><a href="https://support.e-probatio.com/soft/rwdriver/scr3310-v851.pdf" target="_blank"　className="blank" rel="noreferrer" style={{display:"inline"}}>(795KB)（PDF）</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="note">
                                    <ul>
                                        <li>Windows は米国 Microsoft Corporation の米国およびその他の国における商標または登録商標です。</li>
                                        <li>記載されている社名および商品名などは各社の商標または登録商標です。</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Breadcrumbs routes={routes} />
            </Body>
    </>
    );
}

export default ICReader;