import { useEffect } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import EyeCatch from "../../components/common/EyeCatch";
import Body from "../../components/layout/Body";

const Download = () => {
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/support/tool/download", breadcrumb: "ダウンロード"},
    ]
    useEffect(() => {
        document.title = "ダウンロード | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );
    }, [])
    return (
        <>
            <Body bodyClass="download">
                <EyeCatch title="ダウンロード"/>
                <div className="page-content">

                    <div className="download__block download__intro">
                        <div className="inner">
                            <h2 className="download__block__ttl">ダウンロードファイル</h2>
                        </div>
                        <div className="cont">
                            <table className="default tbl_1">
                                <thead>
                                    <tr>
                                        <th className="th"  rowSpan={2}>ソフトウェア名</th>
                                        <th className="th version" colSpan={2}>対応OS（Windows）</th>
                                        <th className="th" rowSpan={2}>ファイル名</th>
                                    </tr>
                                    <tr>
                                        <th className="th version">10</th>
                                        <th className="th version">11</th>
                                    </tr>
                                    <tr>
                                        <th className="th">PKIドライバー</th>
                                        <td className="td">〇</td>
                                        <td className="td">〇</td>
                                        <td className="td"><a href="https://support.e-probatio.com/soft-t/installer/S9PKIWP_Setup.exe">S9PKIWP_Setup.exe (4.62MB)</a></td>
                                    </tr>
                                </thead>
                                
                            </table>
                        </div>
                    </div>
                    
                    <div id="s01" className="download__block download__pages download01">
                        <div className="inner">
                            <div className="download__contents">
                                <div id="s1-1" className="download__contents__box open">
                                    <div className="cont">
                                        <h4 className="ttl">インストール手順</h4>
                                        <p className="txt">
                                            <strong>◆PKIドライバーが「未インストール」の場合</strong>
                                        </p>
                                        <ol>
                                            <li>ファイル名「S9PKIWP_Setup.exe」をクリックして、「実行」してください。</li>
                                            <li> 『PKI Driver～ セットアップウィザード』画面は『次へ』をクリックします。</li>
                                            <li>『PKI Driver～ インストール先フォルダ指定』画面は『次へ』をクリックします。</li>
                                            <li> 『ファイルコピー開始』の画面は『次へ』をクリックします。</li>
                                            <li>『PKI～ インストールの完了』画面になりましたら、「いいえ、後でコンピュータを再起動します。」を選択し、『完了』をクリックします。</li>
                                        </ol>
                                        <p className="txt">
                                            インストールが終わりましたら、「閉じる」ボタンをクリックして終了してください。<br/>
                                            詳しいインストール手順はマニュアルをご覧ください。
                                            </p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <Breadcrumbs routes={routes} />
            </Body>

        </>
    );
}

export default Download;