import Breadcrumbs from "../../components/common/Breadcrumbs";
import EyeCatch from "../../components/common/EyeCatch";
import Body from "../../components/layout/Body";
import { News, NewsBody, AttachedImage } from "../../components/news/types";
import { File } from "../../components/common/types"
import AttachedFile from "../../components/common/File"
import { categories } from "../../components/news/const";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

/**
 * 添付ファイル
 */
const NewsFile = (props: {file: File}) => {
    const { file } = props;
    return (
        <>
            <p><a href={file.path} target="_blank"  className="pdf" download rel="noreferrer">{file.name}</a></p>
        </>
    );
}

/**
 * 画像
 */
const NewsImage = (props: {image: AttachedImage}) => {
    const { image } = props;
    return (
        <>
            {image.path !== "" ? (
                <div className="flexItem">
                    <figure>
                        <a href={image.path} className="js-modal-img"><img src={image.path} alt={image.alt} /></a>
                        <figcaption>{image.caption}</figcaption>
                    </figure>
                </div>
            ) : <></>}
        </>
    )
}

/**
 * 新着情報のbodyの中身
 * bodyは配列となっているため、複数News
 */
const NewsContent = (props: {body: NewsBody, files: File[]|null}) => {
    const { body, files } = props;
    return (
        <>
            <div className="news__content">
                <div className="inner">
                    <div dangerouslySetInnerHTML={{__html: body.text}}></div>
                    <div className="news__gallery">
                        <div className="flexBox">
                            {
                                body.images.map((image, index) => {
                                    return (
                                        <NewsImage key={index} image={image} />
                                    )
                                })
                            }
                        </div>
                    </div>
                    { files && files.length > 0 ? (
                        <>
                            {files.map((file, i) => <AttachedFile file={file} key={i} />)}
                            <div className="news__gotoArchive">
                                <a href="/news" className="e_btn type_back">新着情報一覧へ</a>
                            </div>
                        </>
                    ) : <></>}
                </div>
            </div>
        </>
    )
}

/**
 * 新着情報詳細ページ
 */
const NewsPage = (props: {newsList: News[], category: string}) => {
    const { newsList, category } = props;
    const { newsId } = useParams();
    const news = newsList.length > 0 ? newsList.find(item => String(item.id) === newsId)! : null;
    const currentCategory = categories.find(item => item.key === category)!;
    const breadcrumbUrl = currentCategory.key === "supported_system_information" ? "system" : currentCategory.key;
    const routes = news ? [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/news", breadcrumb: "新着情報"},
        { path: `/news/${breadcrumbUrl}/${String(news.id)}` , breadcrumb: news.title},
    ] : [];
    useEffect(() => {
        // 記事IDが見つからない時は新着記事トップへリダイレクト
        if (typeof news === "undefined") window.location.replace("/");
        if (news) {
            // フォーマットがリンクになっている場合は該当URLへリダイレクト
            if (news.format === "リンク") window.location.replace(news.link);
            document.title = `${news.title} | 新着情報 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス`;
            document.querySelector('meta[name="description"]')
                ?.setAttribute(
                    "content", 
                    "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
                );
        }
    }, [news])
    return news ? (
        <>
            <Body bodyClass="news">
                <EyeCatch title="新着情報" />
                <div className="page-content">
                    <div className="news__header">
                        <div className="inner">
                            <div className="news__date">
                                <span className="date">{news.publish_date}</span>
                                {
                                    news.service.map((service, index) => {
                                        return <span key={index} className="status">{service}</span>
                                    })
                                }
                            </div>
                            <div className="news__category">
                                <ul>
                                    {category ? <li><span className={category === "supported_system_information" ? "system" : category}>{categories.find(item => item.key === category)?.name}</span></li> : ""}
                                    {news.important_announcement ? <li><span className="important">重要なお知らせ</span></li> : <></>}
                                </ul>
                            </div>
                            <h2 className="news__title">{news.title}</h2>
                        </div>
                    </div>
                    {
                        news.body.map((body, index) => {
                            return (
                                <NewsContent key={index} body={body} files={index + 1 === news.body.length ? news.file : null} />
                            )
                        })
                    }
                    <div className="modal-wrap"><div className="modal-inner"><div className="modal-image"><span className="js-modal-close"></span></div></div></div>
                </div>
                <Breadcrumbs routes={routes} />
            </Body>
        </>
    ) : <></>
}

export default NewsPage