import Body from '../../components/layout/Body';
import EyeCatch from "../../components/common/EyeCatch";
import CTAButton from "../../components/common/CTAButton";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import Picture from "../../components/common/Picture";
import IntroductionList from "./IntroductionList";
import {introductionListItems} from "./introduction_list_items";
import { useEffect } from 'react';

const Introduction = () => {
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/about", breadcrumb: "e-Probatio PS2について"},
        { path: "/about/introduction", breadcrumb: "導入・ご利用のイメージ"},
    ]
    useEffect(() => {
        document.title = "導入・ご利用のイメージ | e-Probatio について | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "JACIC/SCOPE版電子入札コアシステムに対応、電子入札・開札システム、電子申請届出システムに対応、政府認証基盤（GPKI）のブリッジ認証局に接続、電子署名法に基づく特定認証業務の認定取得"
            );
    }, [])
    return (
        <>
            <Body bodyClass="introduction">
                <EyeCatch
                    title="導入・ご利用のイメージ"
                    description="電子証明書（ICカード）のお申し込みから利用方法のご案内となります。"
                    imgPath="/assets/images/introduction/mv_img.png"
                />
                <div className="page-content">

                    <div className="introduction__block">
                        <div className="inner">

                            <div className="cont1">
                                <p className="introduction__lead">電子入札を例にして、電子認証の流れをご説明いたします。</p>
                                <IntroductionList listItems={introductionListItems} />
                            </div>

                            <div className="cont2">
                                <h2 className="introduction__ttl">電子認証の流れ</h2>
                                <Picture
                                    figureClass="introduction__img1"
                                    sourcePath="/assets/images/introduction/img_1_sp.png"
                                    imgPath="/assets/images/introduction/img_1.png"
                                    figCaption="※サービスのイメージ"
                                />
                            </div>

                        </div>
                    </div>

                </div>

                <CTAButton
                    leftBtn={{link: "../../query/first", text: "新規申込方法"}}
                    centerBtn={{link: "../../price", text: "価格について"}}
                    rightBtn={{link: "../../faq", text: "よくあるご質問"}}
                />
                <Breadcrumbs routes={routes} />
            </Body>
        </>
    );
}

export default Introduction;