import { useEffect } from 'react';
import Breadcrumbs from '../../../components/common/Breadcrumbs';
import EyeCatch from '../../../components/common/EyeCatch';
import Body from '../../../components/layout/Body';


const Reissue = () => {
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/query", breadcrumb: "お申し込みのご案内"},
        { path: "/query/reissue", breadcrumb: "再発行の申込方法"},
    ];
    useEffect(() => {
        document.title = "再発行の申込方法 | お申し込みのご案内 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );
    }, [])
    return (
        <>
            <Body bodyClass="queryReissue">
                <EyeCatch
                    title="再発行の申込方法"
                    description="登録情報に変更があった、暗証番号（PIN)を紛失した等で、改めて新しい電子証明書（ICカード）をお申し込みされる場合のご案内となります。"
                    imgPath="/assets/images/query/h1_query_reissue.png"
                />
                        <div className="page-content">

                            <div className="queryReissue__block intro">
                                <div className="inner">
                                    <p>名義人や住所の変更、ICカードの紛失等によって再度、ICカードをお申し込みされるお客さまへのご案内です。<br />
                                        再発行の理由によって手続きが異なる場合がありますので、フローチャートを参考にして各説明に進んでください。<br />
                                        ※ICカードのお申し込みをいただきますとキャンセルはいたしかねます。
                                    </p>
                                </div>
                            </div>

                            <div className="queryReissue__block queryReissue__questionnaire q1 open">
                                <div className="inner">
                                    <h3 className="questionnaire__q"><i className="icon_q">Q.</i>再発行にあたり内容に変更はありますか？</h3>
                                    <p><small>ICカード・PIN紛失等の場合は「いいえ」を選択してください。</small></p>
                                    <div className="questionnaire__a">
                                        <div className="flexBox queryReissue__selector" data-num="1">
                                            <div className="flexItem">
                                                <span className="e_btn type_input reissueSelBtn" data-select="reissue_1_1">はい</span>
                                            </div>
                                            <div className="flexItem">
                                                <span className="e_btn type_input reissueSelBtn" data-select="reissue_1_2">いいえ</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="queryReissue__block queryReissue__questionnaire q2">
                                <div className="inner">
                                    <h3 className="questionnaire__q"><i className="icon_q">Q.</i>名義人を変更しますか？</h3>
                                    <div className="questionnaire__a">
                                        <div className="flexBox queryReissue__selector" data-num="2">
                                            <div className="flexItem">
                                                <span className="e_btn type_input reissueSelBtn" data-select="reissue_2_1">はい</span>
                                            </div>
                                            <div className="flexItem">
                                                <span className="e_btn type_input reissueSelBtn" data-select="reissue_2_2">いいえ</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="queryReissue__block queryReissue__questionnaire q3">
                                <div className="inner">
                                    <h3 className="questionnaire__q"><i className="icon_q">Q.</i>連絡先を変更しますか？</h3>
                                    <div className="questionnaire__a">
                                        <div className="flexBox queryReissue__selector" data-num="3">
                                            <div className="flexItem">
                                                <span className="e_btn type_input reissueSelBtn" data-select="reissue_3_1">はい</span>
                                            </div>
                                            <div className="flexItem">
                                                <span className="e_btn type_input reissueSelBtn" data-select="reissue_3_2">いいえ</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="queryReissue__block queryReissue__questionnaire q4">
                                <div className="inner">
                                    <h3 className="questionnaire__q"><i className="icon_q">Q.</i>会社住所・名義人住所・社名を変更しますか？</h3>
                                    <div className="questionnaire__a">
                                        <div className="flexBox queryReissue__selector" data-num="4">
                                            <div className="flexItem">
                                                <span className="e_btn type_input reissueSelBtn" data-select="reissue_4_1">はい</span>
                                            </div>
                                            <div className="flexItem">
                                                <span className="e_btn type_input reissueSelBtn" data-select="reissue_4_2">いいえ</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="queryReissue__block queryReissue__bottom msg_1">
                                <div className="inner">
                                    <p className="t1">
                                        市町村合併など失効申請・再発行が不要なケースもございます。<br />
                                        必ず事前にICカードの失効申請・再発行が必要か各発注機関にお問い合わせください。<br />
                                        失効申請・再発行が必要なお客さまは次の「申込方法の手順を確認する」から詳細手順をご参照ください。</p>
                                    <p className="link"><a href="/query/reissue/step" className="e_btn">申込方法の手順を確認する</a></p>
                                </div>
                            </div>

                            <div className="queryReissue__block queryReissue__bottom msg_2">
                                <div className="inner">
                                    <p className="link"><a href="/query/reissue/step" className="e_btn">申込方法の手順を確認する</a></p>
                                </div>
                            </div>
                            <div className="queryReissue__block queryReissue__bottom msg_3 footer__gnavi">
                                <div className="inner">
                                    <p>
                                        連絡先が変更になるお客さまへのご案内
                                    </p>
                                    <p className="t2_1">
                                        下記のボタンから連絡先変更のお問い合わせができます。
                                    </p>
                                    <p className="contactBtn" style={{marginBottom: 40}}><a href="/contact/">お問い合わせ</a></p>
                                    <h3>お電話でのご依頼</h3>
                                    <p className="telTxt"><i className="fas fa-phone"></i><a href="tel:0120-851-240" className="phone" style={{fontSize: 20, fontWeight: 700}}>0120-851-240</a></p>
                                    <p className="t2_1">※お電話が繋がりにくい場合がございます。<br />
                                        ご不明な点や、継続に関するお問い合わせはメールをおススメいたします。
                                    </p>
                                </div>
                            </div>
                        </div>


                <Breadcrumbs routes={routes} />
            </Body>
        </>
    );
}

export default Reissue;