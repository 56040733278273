import { useEffect } from 'react';
import Breadcrumbs from '../../../components/common/Breadcrumbs';
import EyeCatch from '../../../components/common/EyeCatch';
import Body from '../../../components/layout/Body';

/**
 *  継続の申込方法ページ
 */
const Continue = () => {
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/query", breadcrumb: "お申し込みのご案内"},
        { path: "/query/continue", breadcrumb: "継続の申込方法"},
    ];
    useEffect(() => {
        document.title = "継続の申込方法 | お申し込みのご案内 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );
    }, [])
    return (
        <>
            <Body bodyClass="queryContinue">
                <EyeCatch
                    title="継続の申込方法"
                    description="現在お持ちの電子証明書（ICカード）の有効期間満了に伴って、継続のお申し込みされる場合のご案内となります。"
                    imgPath="/assets/images/query/h1_query_continue.png"
                />
                        <div className="page-content">

                            <div className="queryContinue__block intro">
                                <div className="inner">
                                <p>以前に弊社にてお申し込みいただき、有効期限が近づいているお客さまへのご案内です。<br />
                                    届いた書類の修正が必要な場合がありますので、下記の質問にお答えいただき、お客さまに合った説明ページへお進みください。<br />
                                    ※ICカードのお申し込みをいただきますとキャンセルはいたしかねます。</p>
                                </div>
                            </div>

                            <div className="queryContinue__block queryContinue__questionnaire q1 open">
                                <div className="inner">
                                    <h3 className="questionnaire__q"><i className="icon_q">Q.</i>継続の案内は届いていますか？</h3>
                                    <div className="questionnaire__a">
                                        <div className="flexBox queryContinue__selector" data-num="1">
                                            <div className="flexItem">
                                                <span className="e_btn type_input selBtn" data-select="1_1">はい</span>
                                            </div>
                                            <div className="flexItem">
                                                <span className="e_btn type_input selBtn" data-select="1_2">いいえ</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="queryContinue__block queryContinue__questionnaire q2">
                                <div className="inner">
                                    <h3 className="questionnaire__q"><i className="icon_q">Q.</i>印字された申込書の内容に変更がありましたか？</h3>
                                    <div className="questionnaire__a">
                                        <div className="flexBox queryContinue__selector" data-num="2">
                                            <div className="flexItem">
                                                <span className="e_btn type_input selBtn" data-select="2_1">はい</span>
                                            </div>
                                            <div className="flexItem">
                                                <span className="e_btn type_input selBtn" data-select="2_2">いいえ</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="queryContinue__block queryContinue__questionnaire q3">
                                <div className="inner">
                                    <h3 className="questionnaire__q"><i className="icon_q">Q.</i>変更項目を選んでください。</h3>
                                    <div className="questionnaire__a">
                                        <div className="flexBox queryContinue__selector" data-num="3">
                                            <div className="flexItem">
                                                <span className="e_btn type_input selBtn" data-select="3_1">名義人の変更</span>
                                            </div>
                                            <div className="flexItem">
                                                <span className="e_btn type_input selBtn" data-select="3_2">その他の変更</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="queryContinue__block queryContinue__bottom msg_1">
                                <div className="inner">
                                    <p className="link"><a href="/query/continue/step" className="e_btn">申込方法の手順を確認する</a></p>
                                </div>
                            </div>

                            <div className="queryContinue__block queryContinue__bottom msg_2">
                                <div className="inner">
                                <p className="t2_1">継続の案内が届いていないお客さまへ<br />
                                    ICカードの有効期限が切れる約3ヶ月前に、弊社よりICカード名義人宛に<br className="pc" />
                                    「ICカード継続利用に関するご案内」を送付（郵送）しております。<br className="pc" />
                                    大変恐れ入りますが、本書類到着までしばらくお待ちください。<br className="pc" />
                                    万一、2ヶ月前を過ぎても届かない場合は下記のページからお問い合わせください。</p>
                                    <p className="contactBtn"><a href="/contact/">お問い合わせ</a></p>
                                </div>
                            </div>

                            <div className="queryContinue__block queryContinue__bottom msg_3">
                                <div className="inner">
                                    <p className="link"><a href="/query/first" className="e_btn first">新規申込方法の手順を確認する</a></p>
                                </div>
                            </div>

                            <div className="queryContinue__block queryContinue__bottom msg_4">
                                <div className="inner">
                                    <p className="t2_1">お手元に届いた「ICカード利用申込書」を修正いただきお申し込みください。</p>
                                    <p className="link"><a href="/query/continue/step" className="e_btn">申込方法の手順を確認する</a></p>
                                </div>
                            </div>

                        </div>


                <Breadcrumbs routes={routes} />
            </Body>
        </>
    );
}

export default Continue;