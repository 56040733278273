type CtaButton = {
    link: string;
    text: string;
}
/**
 * ページ下部のCTA(Call to action)ボタンゾーン
 */
const CTAButton = (props: {leftBtn: CtaButton, centerBtn: CtaButton, rightBtn: CtaButton}) => {
    const { leftBtn, centerBtn, rightBtn } = props;
    return (
        <div className="page-footer">
            <div className="inner">
                <div className="page-footer__head">
                    <div className="flexBox">
                        <div className="flexItem">
                            <figure className="img"><img src="/assets/images/common/pf_img.png" alt=""/></figure>
                        </div>
                        <div className="flexItem">
                            <h2>お申し込みでお悩みの方へ</h2>
                            <p>e-Probatioがもっとわかるコンテンツをご紹介します</p>
                        </div>
                    </div>
                </div>
                <div className="page-footer__body">
                    <div className="flexBox">
                        <div className="flexItem">
                            <p className="linkBtn"><a href={leftBtn.link} className="e_btn type03">{leftBtn.text}</a></p>
                        </div>
                        <div className="flexItem">
                            <p className="linkBtn"><a href={centerBtn.link} className="e_btn type03">{centerBtn.text}</a></p>
                        </div>
                        <div className="flexItem">
                            <p className="linkBtn"><a href={rightBtn.link} className="e_btn type03">{rightBtn.text}</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CTAButton;
