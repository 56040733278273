import React from "react";
import { Link } from "react-router-dom";
import NavGroup from "./NavGroup";
import NavItem from "./NavItem";
import { aboutNavSubItems, queryNavSubItems, priceNavSubItems, supportNavSubItems } from "./nav_items";
import { useCookies } from 'react-cookie';

/**
 * フッターメニューのコンポーネント
 * @returns Footer
 */
const Footer = () => {
    const [cookies, setCookie, removeCookie] = useCookies(["acceptCookie"]);



    return (
        <>
            <footer className="site-footer">
                <div className="site-footer__inner footer1">
                    <div className="footer__gnavi">
                        <div className="footer__gnavi__info">
                            <p className="footer__gnavi__msg">e-Probatioに関する各種お問い合わせはこちらから</p>
                            <p className="contactBtn"><a href="/contact/">お問い合わせ</a></p>
                            <p className="footer__gnavi__tool"><a href="/web-prob/" target="_blank" rel="noreferrer">申し込み書作成ツール「ぷろぱてぃお君」</a></p>
                        </div>
                        <nav>
                            <ul className="menu">
                                <NavItem 
                                    title="e-Probatioトップページ"
                                    url="/"
                                    isSp={false}
                                />
                                <NavGroup 
                                    title="e-Probatio PS2について"
                                    url="/about"
                                    navSubItems={aboutNavSubItems}
                                    element="footer"
                                />
                                <NavGroup 
                                    title="お申し込みのご案内"
                                    url="/query"
                                    navSubItems={queryNavSubItems}
                                    element="footer"
                                />
                            </ul>
                            <ul className="menu">
                                <NavGroup 
                                    title="価格について"
                                    url="/price"
                                    navSubItems={priceNavSubItems}
                                    element="footer"
                                />
                                <NavGroup 
                                    title="サポート情報"
                                    url="/support"
                                    navSubItems={supportNavSubItems}
                                    element="footer"
                                    isSubSp={true}
                                />
                                <NavItem 
                                    title="よくあるご質問"
                                    url="/faq"
                                    isSp={false}
                                />
                                <NavItem 
                                    title="新着情報"
                                    url="/news"
                                    isSp={false}
                                />
                                <NavItem 
                                    title="公開情報"
                                    url="/footer/info"
                                    isSp={false}
                                />
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className={`site-footer__inner footer2 ${cookies.acceptCookie ? "" : "cookie_height"}`}>
                    <div className="footer__nav2">
                        <nav>
                            <ul>
                                <NavItem 
                                    title="企業情報"
                                    url="https://www.nttbizsol.jp/"
                                    isSp={false}
                                    target="_blank"
                                />
                                <NavItem 
                                    title="サイトポリシー"
                                    url="/sitepolicy"
                                    isSp={false}
                                />
                                <NavItem 
                                    title="プライバシーポリシー"
                                    url="https://www.nttbizsol.jp/company/policy/"
                                    isSp={false}
                                    target="_blank"
                                />
                                <NavItem 
                                    title="関連情報"
                                    url="/themes"
                                    isSp={false}
                                />
                                <NavItem 
                                    title="サイトマップ"
                                    url="/sitemap"
                                    isSp={false}
                                />
                            </ul>
                        </nav>
                        <div className="copyright">&copy; 2022  ＮＴＴビジネスソリューションズ株式会社</div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;