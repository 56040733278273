import { useEffect } from 'react';
import EyeCatch from '../../components/common/EyeCatch';
import Body from '../../components/layout/Body';
import CTAButton from "../../components/common/CTAButton";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import Picture from "../../components/common/Picture";

/**
 * e-Probatio PS2の特長ページ
 */
const Feature = () => {
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/about", breadcrumb: "e-Probatio PS2について"},
        { path: "/about/feature", breadcrumb: "e-Probatio PS2の特長"},
    ];
    useEffect(() => {
        document.title = "e-Probatio PS2の特長 | e-Probatio について | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "「e-Probatio PS2サービス」で発行するICカードは、「電子入札コアシステム1」、「電子入札・開札システム」、「電子申請・届出システム」、「地方税ポータルシステム（eLTAX）」、「電子契約に係わるシステム」、に対応しています。"
            );
    }, [])
    return (
        <>
            <Body bodyClass="feature">
                <EyeCatch
                    title="e-Probatio PS2の特長"
                    description="電子証明書（ICカード）、パソコンから電子入札や電子申請などが行えます。"
                    imgPath="/assets/images/feature/mv_img.png"
                />
                <div className="page-content">

                    <div className="feature__block">
                        <div className="inner">

                            <div className="cont1">
                                <div className="flexBox">
                                    <div className="flexItem text">
                                        <p className="feature__lead">インターネット経由で安全な取引を可能にする電子証明書を発行いたします。弊社の電子証明書には下記の特長があります。</p>
                                        <dl className="feature__list">
                                            <dt>特長<em>01</em></dt>
                                            <dd>JACIC/SCOPE版電子入札コアシステムに対応</dd>
                                        </dl>
                                        <dl className="feature__list">
                                            <dt>特長<em>02</em></dt>
                                            <dd>電子入札・開札システム、電子申請届出システムに対応</dd>
                                        </dl>
                                        <dl className="feature__list">
                                            <dt>特長<em>03</em></dt>
                                            <dd>政府認証基盤（GPKI）のブリッジ認証局に接続</dd>
                                        </dl>
                                        <dl className="feature__list">
                                            <dt>特長<em>04</em></dt>
                                            <dd>電子署名法に基づく特定認証業務の認定取得</dd>
                                        </dl>
                                    </div>
                                    <div className="flexItem img">
                                        <Picture figureClass="feature__img1" imgPath="/assets/images/feature/feature_img_1.png" />
                                    </div>
                                </div>
                            </div>

                            <div className="cont2">
                                <h2 className="feature__ttl">複数のサービスに対応しています。</h2>
                                <Picture
                                    figureClass="feature__img2"
                                    sourcePath="/assets/images/feature/feature_img_2_sp.png"
                                    imgPath="/assets/images/feature/feature_img_2.png"
                                />
                                <div className="feature__note">
                                    <ul>
                                        <li><span>※1</span>一般財団法人日本建設情報総合センター（略称JACIC）及び一般財団法人港湾空港建設技術センター（略称SCOPE）が設立した「電子入札コアシステム開発コンソーシアム」により開発されたシステムです。</li>
                                        <li><span>※2</span>中央省庁が導入している電子入札・開札システムへアクセスする場合、法人の代表者の電子証明書を法務省商業登記認証局より取得していただく必要がありますので、ご注意ください。（個人事業主の方は含みません。）</li>
                                        <li><span>※3</span>「民間事業者等が行う書面の保存等における情報通信の技術の利用に関する法律」および「同法律の施行に伴う関係法律の整備等に関する法律」の2つを指します。</li>
                                        <li><span>※4</span>「書面の交付等に関する情報通信の技術の利用のための関係法律の整備に関する法律」を指します。</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <CTAButton
                    leftBtn={{link: "../../query/first", text: "新規申込方法"}}
                    centerBtn={{link: "../../price", text: "価格について"}}
                    rightBtn={{link: "../../faq", text: "よくあるご質問"}}
                />
                <Breadcrumbs routes={routes} />
            </Body>
        </>
    );
}

export default Feature;