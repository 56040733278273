import {useEffect, useState} from "react";
import BidApplication from "../../components/about/system/BidApplication";
import {System, Category, Area} from "../../components/about/system/Types"
import axios from "axios";
import Body from '../../components/layout/Body';
import TaxPayment from "../../components/about/system/TaxPayment";
import EContract from "../../components/about/system/eContract";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import EyeCatch from "../../components/common/EyeCatch";


const SystemMap = () => {
    const routes = [
        {path: "/", breadcrumb: "e-Probatio トップ"},
        {path: "/about", breadcrumb: "e-Probatio PS2について"},
        {path: "/about/system", breadcrumb: "システム対応マップ"},
    ];
    const [bidAppSystems, setBidAppSystems] = useState<System[]>([])
    const [taxPaymentSystems, setTaxPaymentSystems] = useState<System[]>([])
    const [eContractSystems, setEContractSystems] = useState<System[]>([])

    useEffect(() => {
        axios.get("/cms/json/about/system/data.json")
            .then(response => response.data)
            .then((systems: System[]) => {
                const bas: System[] = [];
                const tps: System[] = [];
                const ecs: System[] = [];
                systems.forEach(system => {
                    // 入札・申請
                    if (Category.BidApplication.includes(system.category)
                        && (system.area === undefined || Area.Prefectures.includes(system.area)
                            || Area.Chuo === system.area || Area.KoshaKodan === system.area)) {
                        bas.push(system);
                    }

                    // 申告・納税
                    if (Category.TaxPayment.includes(system.category)
                        && (Area.Chuo === system.area || Area.Jichitai === system.area || Area.None === system.area)) {
                        tps.push(system);
                    }

                    // 電子契約
                    if (Category.eContract.includes(system.category)
                        && (Area.None === system.area)) {
                        ecs.push(system);
                    }
                })
                setBidAppSystems(bas);
                setTaxPaymentSystems(tps);
                setEContractSystems(ecs);
            });
    }, [])

    useEffect(() => {
        document.title = "システム対応マップ | e-Probatio について | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );
    }, [])

    return (
        <>
            <Body bodyClass="system">
                <EyeCatch
                    title="システム対応マップ"
                    description="e-Probatio PS2がご利用いただける発注システムの一覧です。"
                    imgPath=""
                />

                <div className="page-content">

                    <div className="system__block system__intro">
                        <div className="inner">
                            <p>ご利用システムにより推奨ブラウザーが異なりますので、利用環境を確認の上、推奨ブラウザーでご利用ください。</p>
                        </div>
                    </div>

                    <div className="system__block system__select">
                        <div className="inner">

                            <div className="system__select__buttons">
                                <div className="flexBox">
                                    <div className="flexItem">
                                        <button className="selectBtn selectBtn_1 on" data-select="s1-1">電子入札・申請</button>
                                    </div>
                                    <div className="flexItem">
                                        <button className="selectBtn selectBtn_1" data-select="s1-2">電子申告・納税</button>
                                    </div>
                                    <div className="flexItem">
                                        <button className="selectBtn selectBtn_1" data-select="s1-3">電子契約</button>
                                    </div>
                                </div>
                            </div>

                            <div className="system__select__contents">

                                <div id="s1-1" className="system__select__contents__box selectBox_1 open">
                                    <BidApplication systems={bidAppSystems}/>
                                </div>

                                <div id="s1-2" className="system__select__contents__box selectBox_1">
                                    <TaxPayment systems={taxPaymentSystems}/>
                                </div>

                                <div id="s1-3" className="system__select__contents__box selectBox_1">
                                    <EContract systems={eContractSystems}/>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

                <Breadcrumbs routes={routes}/>
            </Body>
        </>
    );
}

export default SystemMap;