import { useEffect } from 'react';
import Breadcrumbs from '../../../components/common/Breadcrumbs';
import EyeCatch from '../../../components/common/EyeCatch';
import Body from '../../../components/layout/Body';

/**
 * 追加の申込方法ページ
 */
const Append = () => {
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/query", breadcrumb: "お申し込みのご案内"},
        { path: "/query/appnd", breadcrumb: "追加の申込方法"},
    ];
    useEffect(() => {
        document.title = "追加の申込方法 | お申し込みのご案内 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );
    }, [])
    return (
        <>
            <Body bodyClass="queryFirst">
                <EyeCatch
                    title="追加の申込方法"
                    description="すでにお持ちの電子証明書（ICカード）を追加でお申し込みされる場合のご案内となります。"
                    imgPath="/assets/images/query/h1_query_appnd.png"
                />
                <div className="page-content">

                    <div className="queryFirst__block queryFirst__step__intro">
                        <div className="inner">
                            <p>ICカード利用申込書の作成手順をSTEPに沿ってご案内しています。</p>
                        </div>
                    </div>

                    <div className="queryFirst__block queryFirst__step__anchor">
                        <div className="inner">
                            <div className="flexBox">
                                <div className="flexItem">
                                    <a href="#step01" className="anchorBtn">
                                        <div className="step"><span className="flex-align-center"><span>STEP<em className="num">01</em></span></span></div>
                                        <span className="ttl">利用申込書の作成</span>
                                    </a>
                                </div>
                                <div className="flexItem">
                                    <a href="#step02" className="anchorBtn">
                                        <div className="step"><span className="flex-align-center"><span>STEP<em className="num">02</em></span></span></div>
                                        <span className="ttl">必要書類の送付<br />／審査と発行</span>
                                    </a>
                                </div>
                                <div className="flexItem">
                                    <a href="#step03" className="anchorBtn">
                                        <div className="step"><span className="flex-align-center"><span>STEP<em className="num">03</em></span></span></div>
                                        <span className="ttl">ICカードの受取</span>
                                    </a>
                                </div>
                                <div className="flexItem">
                                    <a href="#step04" className="anchorBtn">
                                        <div className="step"><span className="flex-align-center"><span>STEP<em className="num">04</em></span></span></div>
                                        <span className="ttl">内容確認<br className="pc" />／受領書の返送</span>
                                    </a>
                                </div>
                                <div className="flexItem">
                                    <a href="#step05" className="anchorBtn">
                                        <div className="step"><span className="flex-align-center"><span>STEP<em className="num">05</em></span></span></div>
                                        <span className="ttl">お支払い</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div id="step01" className="queryFirst__block queryFirst__step__block step01">
                        <div className="inner">
                            <div className="queryFirst__step__head">
                                <div className="step__title">
                                    <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">01</em></span></span></div>
                                    <div className="step__title__txt">
                                        <h2 className="ttl">利用申込書の作成</h2>
                                    </div>
                                </div>
                                <p className="step__leadTxt btn_text">
                                「ぷろばてぃお君」をクリックして、画面案内にしたがって申込書を作成してください。<br />
                                </p>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">
                                    <div className="queryFirst__contents__box">
                                        <figure className="step_1_1">
                                            <p className="footer__gnavi__tool">
                                                <a href="/web-prob/" target="_blank" rel="noreferrer">
                                                    申し込み書作成ツール「ぷろぱてぃお君」</a>
                                            </p>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <a href="#step02" className="queryFirst__nextstep e_btn_anchor sp">次へ</a>
                        </div>
                    </div>

                    <div id="step02" className="queryFirst__block queryFirst__step__block step02">
                        <div className="inner">
                            <div className="queryFirst__step__head">
                                <div className="step__title">
                                    <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">02</em></span></span></div>
                                    <div className="step__title__txt">
                                        <h2 className="ttl">必要書類の送付<br className="sp" />／審査と発行</h2>
                                    </div>
                                </div>
                                <p className="step__leadTxt">
                                    ICカード申込書と公的機関の証明書類を一緒にご郵送ください。弊社に申込書類が到着しだい、書類に誤りがないか審査し、ICカードを発行します。<br />
                                    申込書類に必要事項をご記入後、書類一式を下記までご郵送ください。郵送事故防止のためにも簡易書留制度のご利用をおススメいたします。
                                </p>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">

                                    <div className="queryFirst__contents__box">
                                        <h3 className="box__title">申込書送付先</h3>
                                        <p>〒530-0003<br />
                                            大阪市北区堂島3-1-21 ＮＴＴデータ堂島ビル16階<br />
                                            ＮＴＴビジネスソリューションズ株式会社<br />
                                            バリューデザイン部　<br className="sp" />
                                            トラストビジネス担当<br />
                                            &nbsp;<br />
                                            お送りいただきました書類を元にICカードを発行いたします。<br />
                                            審査～発送までおよそ<span className="c-e72929">2週間</span>お時間をいただきます。 </p>
                                        <figure className="step_2_1">
                                            <picture>
                                                <source media="(max-width: 767px)" srcSet="../../assets/images/query/first/step_3_1_sp.png" />
                                                <img src="../../assets/images/query/first/step_3_1.png" alt="画像" />
                                            </picture>
                                        </figure>
                                    </div>

                                    <div className="queryFirst__attention">
                                        <h3>ご注意</h3>
                                        <ul>
                                            <li>取得された公的書類の内容と違いなくご記入ください。</li>
                                            <li>郵送いただきました申込書類に修正が生じた場合は、申込書類をご返送させていただいた上でお客さまに修正していただくため、ICカードの発行が遅れる場合がございます。</li>
                                            <li>一旦、ICカード発行のお申し込みをいただきますと、原則としてキャンセルはできません。申込書作成の際は、ガイドや記入例をよくご確認の上で作成してください。</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <a href="#step03" className="queryFirst__nextstep e_btn_anchor sp">次へ</a>
                        </div>
                    </div>

                    <div id="step03" className="queryFirst__block queryFirst__step__block step03">
                        <div className="inner">
                            <div className="queryFirst__step__head">
                                <div className="step__title">
                                    <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">03</em></span></span></div>
                                    <div className="step__title__txt">
                                        <h2 className="ttl">ICカードの受取</h2>
                                    </div>
                                </div>
                                <p className="step__leadTxt">ICカードのお受取りには本人限定郵便を利用いたします。<br />
                                    2種類の受取方法がございますのでお客さまに合ったものを下記よりお選びください。<br />
                                    本人限定受取郵便の詳細については「<a href="https://www.post.japanpost.jp/index.html" target="_blank" className="blank" rel="noreferrer">日本郵便のサイト</a>」でご参照ください。</p>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">

                                    <div className="queryFirst__select__buttons">
                                        <div className="flexBox">
                                            <div className="flexItem"><button className="selectBtn selectBtn_3 on" data-select="s3-1">名義人がお受取りの場合<br className="pc" />(基本型)</button></div>
                                            <div className="flexItem"><button className="selectBtn selectBtn_3" data-select="s3-2">受取代人を指定された場合<br className="pc" />(特例型)</button></div>
                                        </div>
                                    </div>

                                    <div className="queryFirst__select__contents">

                                        <div id="s3-1" className="queryFirst__select__contents__box selectBox_3 open">

                                            <div className="queryFirst__contents__box">
                                                <h3 className="box__title">名義人がお受取りの場合(基本型)</h3>
                                                <figure className="step_3_1"><img src="../../assets/images/query/first/step_4_1.png" alt="画像" /></figure>
                                                <ol>
                                                    <li>ICカードと暗証番号（PIN）を『本人限定受取郵便（基本型）』にて、 名義人住民票ご住所の管轄の取扱郵便局へお届けします。</li>
                                                    <li>管轄の郵便局から名義人の住民票ご住所に郵便物到着のお知らせが届きます。</li>
                                                    <li>名義人のみ管轄の取扱郵便局にて、ICカードをお受取りいただけます。</li>
                                                    <li>ICカードリーダーは宅配便にて指定住所にお送りいたします。</li>
                                                </ol>
                                            </div>

                                            <div className="queryFirst__attention">
                                                <h3>ご注意</h3>
                                                <ul>
                                                    <li>ICカードはご自宅には届きません。</li>
                                                    <li>お申し込まれた後で受取代人を指名する事はできません。</li>
                                                    <li><a href="/faq/#faq1_2914">住民票に、集合住宅（マンション等）の部屋番号の記載が無い場合</a></li>
                                                </ul>
                                            </div>

                                            <div className="queryFirst__send">
                                                <h3>送付内容</h3>
                                                <ul>
                                                    <li>ICカード</li>
                                                    <li>受領書</li>
                                                    <li>受領書返送用封筒</li>
                                                    <li>暗証番号（PIN）</li>
                                                </ul>
                                            </div>

                                        </div>

                                        <div id="s3-2" className="queryFirst__select__contents__box selectBox_3">

                                            <div className="queryFirst__contents__box">
                                                <h3 className="box__title">受取代人を指定された場合(特例型)</h3>
                                                <figure className="step_3_2"><img src="../../assets/images/query/first/step_4_2.png" alt="画像" /></figure>
                                                <ol>
                                                    <li>ICカードを『本人限定受取郵便（特例型）』にて名義人住民票ご住所の管轄の取扱郵便局へお届けします。</li>
                                                    <li>管轄の取扱郵便局から名義人住民票ご住所に郵便物到着のお知らせが届きます。</li>
                                                    <li>受取代人は、名義人住民票ご住所の管轄の取扱郵便局にて、ICカードをお受取りください。</li>
                                                    <li>暗証番号（PIN）は『簡易書留郵便』で名義人住民票ご住所へお届けします。</li>
                                                    <li>ICカードリーダーは宅配便にて指定住所にお送りいたします。</li>
                                                </ol>
                                            </div>

                                            <div className="queryFirst__attention">
                                                <h3>ご注意</h3>
                                                <ul>
                                                    <li>あらかじめお申し込み時に申込書に記入していただく必要があります。</li>
                                                    <li>ICカードと暗証番号（PIN）は別送です。</li>
                                                    <li>受取代人のご住所に郵便到着のお知らせは届きません。</li>
                                                    <li><a href="/faq/#faq1_2914">住民票に、集合住宅（マンション等）の部屋番号の記載が無い場合</a></li>
                                                </ul>
                                            </div>

                                            <div className="queryFirst__send">
                                                <h3>送付内容</h3>
                                                <ul>
                                                    <li>ICカード</li>
                                                    <li>受領書</li>
                                                    <li>受領書返送用封筒</li>
                                                    <li>暗証番号（PIN）</li>
                                                </ul>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                            <a href="#step04" className="queryFirst__nextstep e_btn_anchor sp">次へ</a>
                        </div>
                    </div>

                    <div id="step04" className="queryFirst__block queryFirst__step__block step04">
                        <div className="inner">
                            <div className="queryFirst__step__head">
                                <div className="step__title">
                                    <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">04</em></span></span></div>
                                    <div className="step__title__txt">
                                        <h2 className="ttl">内容確認／受領書の返送</h2>
                                    </div>
                                </div>
                                <p className="step__leadTxt">ICカードをお受取り後は、送付または印刷されたマニュアルに沿って内容確認、利用者登録へお進みください。</p>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">

                                    <div className="queryFirst__contents__box">
                                        <h3 className="box__title">セットアップとICカードの内容確認</h3>
                                        <ol>
                                            <li>まず、「管理ツールダウンロードのご案内」をご参照ください。</li>
                                            <li>電子システムを利用する際に必要なソフトは、<a href="/support/tool" className="blank" target="_blank" rel="noreferrer">こちら</a>よりインストールできます。</li>
                                            <li>「ユーザー名・パスワード」を入力してください。</li>
                                            <li>管理ツールの詳細は<a href="https://support.e-probatio.com/man/setup-2.pdf" target="_blank" className="blank" rel="noreferrer">マニュアル</a>をご参照ください。<br />有効期間前でも行えます。</li>
                                        </ol>
                                    </div>

                                    <div className="queryFirst__contents__box">
                                        <h3 className="box__title">受領書の返送</h3>
                                        <ul>
                                            <li>ICカードの内容をご確認後は、受領書にICカードご名義人の実印（お申込書に押印いただいた印鑑証明書と同じご印鑑）を押印後、早急にご返送ください。</li>
                                            <li>受領書に記載されている返送期日までにご返送の無い場合は、ICカードの失効処理を行う場合がございますので、ご注意ください。</li>
                                            <li>失効した場合でもICカード発行料金のキャンセル・ご返金はいたしませんので、あらかじめご了承ください。（失効後に再発行手続を行う場合は、新規発行と同様の手続きとなります。）</li>
                                        </ul>
                                        <p className="link link_faq"><a href="/faq/#faq1_2914">受領書のFAQへ</a></p>
                                    </div>

                                    <div className="queryFirst__contents__box">
                                        <h3 className="box__title">利用者登録</h3>
                                        <p>利用するシステムへ登録を行います。</p>
                                        <ul>
                                            <li>利用者登録はカードに記載されている有効期間内に行ってください。</li>
                                            <li>詳しい利用者登録方法については各発注機関にお問い合わせください。</li>
                                        </ul>
                                    </div>

                                    <div className="queryFirst__attention">
                                        <h3>ご注意</h3>
                                        <ul>
                                            <li>利用者登録はカードに記載されております有効期間内に行ってください。</li>
                                            <li>ICカードの内容をご確認後は、受領書にICカードご名義人の実印（お申込書に押印いただいた印鑑証明書と同じご印鑑）を押印し、早急にご返送ください。</li>
                                            <li>期限までにご返送のない場合は、ICカードの失効処理を行う場合がありますので、ご注意ください。</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <a href="#step05" className="queryFirst__nextstep e_btn_anchor sp">次へ</a>
                        </div>
                    </div>

                    <div id="step05" className="queryFirst__block queryFirst__step__block step05">
                        <div className="inner">
                            <div className="queryFirst__step__head">
                                <div className="step__title">
                                    <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">05</em></span></span></div>
                                    <div className="step__title__txt">
                                        <h2 className="ttl">お支払い</h2>
                                    </div>
                                </div>
                                <p className="step__leadTxt">ICカードの発送後、 数日以内に別途請求書を送付いたします。<br />
                                    請求書をお受取り後、振込期日内に請求書に記載された指定口座へ、お振込みください。<br />
                                    &nbsp;<br />
                                    なお、セットアップサービス（有償）をお申し込みのお客さまは、セットアップサービス完了後に、請求書を送付いたします。<br />
                                    &nbsp;<br />
                                    お支払い方法は請求書による振込支払いのみです。<br />
                                    振込手数料はお客さまのご負担とさせていただきますので、ご了承ください。<br />
                                    &nbsp;<br />
                                    領収書は発行しておりません。<br />
                                    お振込みにてお支払いの際に発行される振込明細書等をご利用ください。</p>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">

                                    <div className="queryFirst__attention">
                                        <h3>ご注意</h3>
                                        <ul>
                                            <li>お支払い方法は請求書による振込支払いのみです。</li>
                                        </ul>
                                    </div>

                                    <div className="queryFirst__finish__message">
                                        <p>以上で手続きは終了です。</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="queryFirst__pageLink">
                        <h2>お申し込みにあたってはこちらをご利用ください</h2>
                        <div className="cont">
                            <a href="/web-prob/" target="_blank" rel="noreferrer">
                                <figure className="img">
                                    <picture>
                                        <source media="(max-width: 767px)"
                                                srcSet="/assets/images/query/first/pageLink_sp.png"/>
                                        <img src="/assets/images/query/first/pageLink_pc.png" alt=""/>
                                    </picture>
                                </figure>
                                <div className="textarea">
                                    <p className="t1">申込書作成ツール</p>
                                    <h3 className="ttl">「ぷろばてぃお君」</h3>
                                    <p className="t2">パソコンから簡単に利用申込書を作成いただけます。</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <Breadcrumbs routes={routes} />
            </Body>
        </>
    );
}

export default Append;