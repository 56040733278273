type PictureProps = {
    figureClass?: string;
    figCaption?: string;
    sourcePath?: string;
    imgPath: string;
}

/**
 * イメージ画像を表示するコンポーネント
 */
const Picture = (props: PictureProps) => {
    const { figureClass, figCaption, sourcePath, imgPath } = props;
    return (
        <figure className={figureClass ?? ""}>
            { sourcePath ? (
                <picture>
                    <source media="(max-width: 767px)" srcSet={sourcePath} />
                    <img src={imgPath} alt="" />
                </picture>
            ) : <img src={imgPath} alt="" /> }
            { figCaption ? <figcaption>{figCaption}</figcaption> : <></> }
        </figure>
    );
}

export default Picture;
