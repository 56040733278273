import React from "react";
import { File } from "../common/types";

const AttachedFile = (props: {file: File}) => {
    const { file } = props;
    return (
        <>
            {file.path.length
                ? (<div className="faq__item__cont">
                    <p className="link">
                        <a href={file.path}
                           className="pdf"
                           target="_blank"
                           rel="noreferrer"
                        >
                            {file.name}
                        </a>
                    </p>
                </div>)
                : ""
            }
        </>
    );
}

export default AttachedFile;