/**
 * ページトップのアイキャッチゾーン
 */
const EyeCatch = (props: {title: string, description?: string, imgPath?: string}) => {
    const { title, description, imgPath } = props;
    return (
        <div className="page-header">
            <div className="inner">
                <div className="textarea">
                    <h1>{ title }</h1>
                    {description ? <p dangerouslySetInnerHTML={{ __html: description }}></p> : <></>}
                </div>
                { imgPath ? <figure className="img"><img src={ imgPath } alt="" /></figure> : <></>}
            </div>
        </div>
    );
}

export default EyeCatch;
