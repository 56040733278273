import useBreadcrumbs from 'use-react-router-breadcrumbs';

export type BreadcrumbRoutes = {
    path: string;
    breadcrumb: string;
}

/**
 * ぱんくずリスト
 */
const Breadcrumbs = (props: {routes: BreadcrumbRoutes[]}) => {
    const { routes } = props;
    const breadcrumbs = useBreadcrumbs(routes);
    const breadcrumbWithoutSpanTag = breadcrumbs.map(breadcrumb => {
        return {
            pathname: breadcrumb.match.pathname,
            title: String(breadcrumb.match.route?.breadcrumb),
        }
    })
    return (
        <div className="breadcrumb">
            <div className="breadcrumb__in">
                {breadcrumbWithoutSpanTag.map(({pathname, title}, index) => {
                    if(index < breadcrumbWithoutSpanTag.length - 1) {
                        return title !== "undefined" ? (
                            <a key={index} href={ pathname }>{ title }</a>
                        ) : ""
                    } else {
                        return <span key={index}>{ title }</span>
                    }
                })}
            </div>
        </div>
    );
}

export default Breadcrumbs;
