import { BlockItem } from "./CampaignGroup";

const CampaignBlock = (props: {body: BlockItem[]}) => {
    return (
        <>
            {
                props.body.map((item: BlockItem, index: number) => {
                    return (
                        <div className={"campaign__block bl01"} key={index}>
                            <div className="inner">
                                <div className="txtarea" dangerouslySetInnerHTML={{__html: item.text}}></div>
                                {item.image !== '' ? 
                                <figure>
                                    <figcaption>{item.caption}</figcaption>
                                    <img src={"/cms/images/campaign/" + item.image} alt={item.alt} />
                                </figure> : null}
                            </div>
                        </div>
                    )
                })
            }
        </>
    );
}

export default CampaignBlock;