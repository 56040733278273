import { useEffect } from 'react';

export const useResizeObserver = (
  elementRef: React.MutableRefObject<Element | undefined | null>,
  callback: (contentRect: DOMRectReadOnly) => void
) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      callback(entries[0].contentRect);
    });

    elementRef.current && resizeObserver.observe(elementRef.current);

    return () => resizeObserver.disconnect();
  }, [callback, elementRef]);
};
