import { Navigate, Route, Routes } from "react-router-dom";
import Download from "./Download";
import ICReader from "./ICReader";
import Setup from "./Setup";
import Support from "./Support";
import Tool from "./Tool";

const SupportRoute = () => {
    return (
    <>
        <Routes>
            <Route path="" element={<Support />} />
            {/* 旧URLからのリダイレクト: サポート情報 */}
            <Route path="index.html" element={<Navigate to=".." />} />

            <Route path="/tool" element={<Tool />} />
            <Route path="/tool/download" element={<Download />} />
            {/* 旧URLからのリダイレクト: 管理ツールについて */}
            <Route path="/tool-dl/index.html" element={<Navigate to="../tool" />} />

            <Route path="/icreader" element={<ICReader />} />
            {/* 旧URLからのリダイレクト: ICリーダー */}
            <Route path="/download/index.html" element={<Navigate to="../icreader" />} />

            <Route path="/setup" element={<Setup />} />
            {/* 旧URLからのリダイレクト: システムセットアップ */}
            <Route path="/setup/index.html" element={<Navigate to="../setup" />} />
            <Route path="/setup/howto/append.html" element={<Navigate to="../setup" />} />
            <Route path="/setup/howto/first.html" element={<Navigate to="../setup" />} />

            {/* Not Found */}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </>
    );
}

export default SupportRoute;
