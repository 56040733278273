import {useEffect, useState} from "react";
import CampaignGroup, {CampaignMessage} from "../../components/campaign/CampaignGroup";
import axios from "axios";
import CTAButton from "../../components/common/CTAButton";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import Body from "../../components/layout/Body";
import EyeCatch from "../../components/common/EyeCatch";

const Campaign = () => {
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/price", breadcrumb: "価格について"},
        { path: "/price/campaign", breadcrumb: "割引・キャンペーン情報"},
    ];
    const [campaigns, setCampaigns] = useState<CampaignMessage[]>([]);
    useEffect(() => {
        axios.get("/cms/json/price/campaign/data.json")
            .then(response => response.data)
            .then((data: CampaignMessage[]) => {
                setCampaigns(data);
            });
    }, []);
    useEffect(() => {
        document.title = "ICカード価格 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );
    }, [])
    return (
        <>
            <Body bodyClass="campaign">
                <EyeCatch title="割引・キャンペーン情報" />
                <div className="page-content">
                    {campaigns.length ? campaigns.map((campaign: CampaignMessage, index: number) =>
                        <CampaignGroup campaign={campaign} key={index}/>
                    ) : (<>
                        <div className="inner">
                            <div className="campaign__leadTxt">
                                <p>現在実施中のキャンペーンはございません。</p>
                            </div>
                        </div>
                    </>)}
                </div>
                <CTAButton
                    leftBtn={{link: "../query/first", text: "新規申込方法"}}
                    centerBtn={{link: "../price", text: "価格について"}}
                    rightBtn={{link: "../faq", text: "よくあるご質問"}}
                />
                <Breadcrumbs routes={routes} />
            </Body>

        </>
    );
};

export default Campaign;
