import {useEffect, useState} from "react";
import {Area, Regions, System, SystemsPerPrefecture, SystemsPerRegion} from "./Types"
import SystemArea from "./SystemArea";


const BidApplication = (props: { systems: System[] }) => {
    const [chuoKoshaKodan, setChuoKoshaKodan] = useState<System[]>([])
    const [region, setRegion] = useState<SystemsPerRegion>({})
    const [omit, setOmit] = useState<System[]>([])

    useEffect(() => {
        const ckk: System[] = [];
        const rgn: SystemsPerRegion = Object.keys(Regions).reduce((r: SystemsPerRegion, regionName: string) => {
            r[regionName] = Regions[regionName].reduce((p: SystemsPerPrefecture, c: string) => {
                    p[c] = [];
                    return p;
                },
                {});
            return r;
        }, {});
        const om: System[] = [];

        props.systems.forEach((system: System) => {
            if (system.area === undefined) {
                // 省略
                om.push(system);
            } else if (system.area === Area.Chuo || system.area === Area.KoshaKodan) {
                // 中央省庁 or 公社・公団等
                ckk.push(system);
            }
            Object.keys(Regions).forEach((regionName: string) => {
                if (system.area === undefined) return;
                if (Regions[regionName].includes(system.area)) {
                    // index番目のregionに属するsystem.area県の配列に入れる
                    rgn[regionName][system.area].push(system)
                }
            });
        })

        setChuoKoshaKodan(ckk);
        setRegion(rgn);
        setOmit(om);
    }, [props.systems])

    const trimPrefectureType = (prefecture: string) => prefecture.replace(/(都|府|県)$/, '');

    const getSystems = (regionName: string, areaName: string) => {
        if (regionName === "中央省庁 公社・公団等") {
            return chuoKoshaKodan;
        }
        if (regionName in region && areaName in region[regionName]) {
            return region[regionName][areaName];
        }
        return [];
    };

    return (
        <>
            <p className="t1">地方（地域）の「+」をクリックすると該当地域リストを表示します。</p>

            <div className="system__tender__map pc_large">
                <figure className="img"><img src="/assets/images/system/map.png" alt=""/>
                </figure>
                <div className="system__tender__map__perf">
                    {Object.entries({"中央省庁 公社・公団等": ["中央省庁 公社・公団等"], ...Regions}).map(([key, l]: [string, string[]], keyIndex: number) =>
                        <dl className={l.length === 1 ? "" : "js-system-accordion"}
                            data-group={(keyIndex + 1).toString()}
                            key={keyIndex.toString()}>
                            <dt>
                                {l.length === 1
                                    ? <a
                                        href={`#group_${(keyIndex + 1).toString()}`}
                                        data-pref={(keyIndex + 1).toString() + "_1"}
                                    >{trimPrefectureType(key)}</a>
                                    : <>{trimPrefectureType(key)}<i className="icon_openclose"/></>
                                }
                            </dt>
                            {l.length > 1 &&
                            <dd>
                                <ul className="pref__anchor">
                                    {l.map((prefecture: string, prefectureIndex: number) =>
                                        <li key={prefectureIndex.toString()}>
                                            <a
                                                href={`#group_${(keyIndex + 1).toString()}`}
                                                data-pref={`${(keyIndex + 1).toString()}_${(prefectureIndex + 1).toString()}`}
                                            >
                                                {trimPrefectureType(prefecture)}
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </dd>}
                        </dl>
                    )}
                </div>
            </div>

            <div className="system__tender__contents">
                <div className="system__tender__group__wrap">
                    {
                    /*
                        2022-10-30 デザイン側でも以下の実装と同様の実装をassets/js/map_system_data.jsというファイル内で行っているため、
                        map_system_data.jsはReactのpublicディレクトリには置かないこと！（二重出力されてしまうため）
                    */
                    Object.entries({"中央省庁 公社・公団等": ["中央省庁 公社・公団等"], ...Regions}).map(([key, l]: [string, string[]], keyIndex: number) => {
                        return <dl id={`group_${(keyIndex + 1).toString()}`}
                                   className="system__tender__group__elem js-system-accordion"
                                   data-group={(keyIndex + 1).toString()}
                                   key={keyIndex.toString()}>
                            <dt>
                                {trimPrefectureType(key)}<i className="icon_openclose"/>
                            </dt>
                            <dd>
                                {l.length > 1 &&
                                <ul className="system__tender__group__pref__anchor">
                                    {l.map((prefecture: string, prefectureIndex: number) =>
                                        <li key={prefectureIndex.toString()}>
                                            <a href={`#pref_${(keyIndex + 1).toString()}_${(prefectureIndex + 1).toString()}`}>
                                                {trimPrefectureType(prefecture)}
                                            </a>
                                        </li>
                                    )}
                                </ul>}
                                <div className="system__tender__group__pref__wrap">
                                    {l.map((prefecture: string, prefectureIndex: number) => {
                                        const systems = getSystems(key, prefecture);
                                        return (l.length > 1 || systems.length) ? (<div
                                            id={`pref_${(keyIndex + 1).toString()}_${(prefectureIndex + 1).toString()}`}
                                            className="system__tender__group__pref__block"
                                            key={prefectureIndex.toString()}>
                                            <SystemArea title={l.length > 1 ? trimPrefectureType(prefecture) : prefecture}
                                                        systems={systems} />
                                        </div>) : "";
                                    })}
                                </div>
                            </dd>
                        </dl>
                    })}
                </div>
            </div>
        </>
    );
}

export default BidApplication;