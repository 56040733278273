import SystemArea from "./SystemArea";
import {System} from "./Types"

const EContract = (props: { systems: System[] }) => {
    return (
        <>
            <div className="system__tender__contents">
                <div className="system__tender__group__wrap">
                    <dl className="system__tender__group__elem js-system-accordion">
                        <dd>
                            <div className="system__tender__group__pref__wrap">
                                <div className="system__tender__group__pref__block">
                                    <SystemArea title="" systems={props.systems} showArea/>
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </>
    );
}

export default EContract;