import axios from "axios";
import React, {useEffect, useState} from "react";
import { NewsSorter } from "../news/NewsList";
import {News, NewsData} from "../news/types";

const News_top = () => {
    const [newsList, setNewsList] = useState<NewsData>({
        all: [],
        important_announcement: [],
        topics: [],
        maintenance: [],
        supported_system_information: []
    });
    useEffect(() => {
        axios.get("/cms/json/news.json")
            .then(response => response.data)
            .then((data: NewsData) => {
                data.all = [];
                data.topics = data.topics
                    .sort(NewsSorter)
                    .slice(0, Math.min(3, data.topics.length));
                data.maintenance = data.maintenance
                    .sort(NewsSorter)
                    .slice(0, Math.min(3, data.maintenance.length));
                data.supported_system_information = data.supported_system_information
                    .sort(NewsSorter)
                    .slice(0, Math.min(3, data.supported_system_information.length));
                setNewsList(data);
            });
    }, []);

    return (
        <>
            <h2 className="ttl">新着情報</h2>
            <div className="flexBox">
                <div className="cate_topics">
                    <h3 className="cate_ttl">TOPICS</h3>
                    <ul>
                        {newsList.topics.map((news: News) => {
                            return <li key={news.id.toString()}>
                                <div className="meta">
                                    <span className="date">{news.publish_date.split(' ')[0]}</span>
                                    {
                                        news.service.map((service, i) => {
                                            return <span className="tag" key={i}>{service}</span>
                                        })
                                    }
                                </div>
                                <div className="textarea">
                                    <a href={news.format === 'リンク' ? news.link : `/news/topics/${news.id.toString()}`}>{news.title}</a>
                                </div>
                            </li>
                        })}
                    </ul>
                    <div className="link">
                        <a href="/news/topics" className="e_btn_arw_2">TOPICS一覧</a>
                    </div>
                </div>

                <div className="cate_maintenance">
                    <h3 className="cate_ttl">メンテナンス情報</h3>
                    <ul>
                        {newsList.maintenance.map((news: News) => {
                            return <li key={news.id.toString()}>
                                <div className="meta">
                                    <span className="date">{news.publish_date.split(' ')[0]}</span>
                                    {
                                        news.service.map((service, i) => {
                                            return <span className="tag" key={i}>{service}</span>
                                        })
                                    }
                                </div>
                                <div className="textarea">
                                    <a href={news.format === 'リンク' ? news.link : `/news/maintenance/${news.id.toString()}`}>{news.title}</a>
                                </div>
                            </li>
                        })}
                    </ul>
                    <div className="link">
                        <a href="/news/maintenance" className="e_btn_arw_2">メンテナンス情報一覧</a>
                    </div>
                </div>

                <div className="cate_system">
                    <h3 className="cate_ttl">対応システム情報</h3>
                    <ul>
                        {newsList.supported_system_information.map((news: News) => {
                            return <li key={news.id.toString()}>
                                <div>
                                    <div className="meta">
                                        <span className="date">{news.publish_date.split(' ')[0]}</span>
                                            {
                                                news.service.map((service, i) => {
                                                    return <span className="tag" key={i}>{service}</span>
                                                })
                                            }
                                    </div>
                                    <div className="textarea">
                                        <a href={news.format === 'リンク' ? news.link : `/news/system/${news.id.toString()}`}>{news.title}</a>
                                    </div>
                                </div>
                            </li>
                        })}
                    </ul>
                    <div className="link">
                        <a href="/news/system" className="e_btn_arw_2">対応システム情報一覧</a>
                    </div>
                </div>

            </div>
        </>
    )
}
export default News_top;
