import { NavSubItem } from "./NavGroup";

// ヘッダー or フッターのナビメニューが持つサブグループの一覧データを定義

export const aboutNavSubItems: NavSubItem[] = [
    {
        title: "e-Probatio PS2の特長",
        url: "/about/feature",
    },
    {
        title: "導入・ご利用のイメージ",
        url: "/about/introduction",
    },
    {
        title: "システム対応マップ",
        url: "/about/system",
    },
];

export const queryNavSubItems: NavSubItem[] = [
    {
        title: "新規の申込方法",
        url: "/query/first",
    },
    {
        title: "継続の申込方法",
        url: "/query/continue",
    },
    {
        title: "追加の申込方法",
        url: "/query/appnd",
    },
    {
        title: "再発行の申込方法",
        url: "/query/reissue",
    },
    {
        title: "失効の申込方法",
        url: "/query/invalidation",
    },
];

export const priceNavSubItems: NavSubItem[] = [
    {
        title: "ICカード価格",
        url: "/price/#s01",
    },
    {
        title: "ICカードリーダー価格",
        url: "/price/#s02",
    },
    {
        title: "セットアップ価格",
        url: "/price/#s03",
    },
];

export const supportNavSubItems: NavSubItem[] = [
    {
        title: "管理ツールについて",
        url: "/support/tool",
    },
    {
        title: "ICカードリーダーについて",
        url: "/support/icreader",
    },
    {
        title: "システムセットアップ",
        url: "/support/setup",
    },
];

export const faqNavSubItems: NavSubItem[] = [
    {
        title: 'e-Probatio PS2 お申し込み<br class="pc">に関するご質問',
        url: "/faq/#faq__anchor_a",
    },
    {
        title: 'e-Probatio PS2 セットアップ・<br class="pc">システムに関するご質問',
        url: "/faq/#faq__anchor_b",
    },
];
