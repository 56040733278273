import { useEffect } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import CTAButton from "../../components/common/CTAButton";
import EyeCatch from "../../components/common/EyeCatch";
import Picture from "../../components/common/Picture";
import Body from "../../components/layout/Body";
import useHashLink from "../../hooks/useHashLink";

const Price = () => {
    useHashLink();
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/price", breadcrumb: "価格について"},
    ]
    useEffect(() => {
        document.title = "価格について | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "電子入札などでは、ICカードが必須となります。ご利用いただくには、ICカードリーダーも必要になります。パソコンへのソフトウェアのインストールおよびICカードの動作検証などを行うセットアップサービス（有料）もご用意しております。"
            );
            
        // 公開情報のCSSが干渉するため、該当CSSのJSでhref属性をクリアしている
        document.getElementById("footer_info")?.setAttribute("href", "")
    }, [])
    return (
    <>
        <Body bodyClass="price">
            <EyeCatch
                title="価格について"
                description="e-Probatio PS2のICカード・ICカードリーダー・セットアップ価格についてのご案内となります。"
                imgPath="/assets/images/price/mv_img.png"
            />
                <div className="page-content">

                    <div className="price__block intro">
                        <div className="inner">
                            <p className="leadTxt">e-Probatio PS2では、以下の各サービスを提供しています。<br />
                            電子入札などでは、ICカードが必須となります。また初めてご利用いただく方は、ICカードリーダーも必要になります。 <br />
                            パソコンへのソフトウェアのインストールおよびICカードの動作検証などを行うセットアップサービス（有料）もご用意しております。<br />
                                {"インターネットに接続できる環境とパソコン（Windows)はお客さまでご用意ください。 "}
                            </p>
                        </div>
                    </div>

                    <div className="anchor price__anchor">
                        <ul>
                            <li><a href="#s01" className="e_btn_anchor">ICカード<br className="sp" />価格</a></li>
                            <li><a href="#s02" className="e_btn_anchor">ICカードリーダー価格</a></li>
                            <li><a href="#s03" className="e_btn_anchor">セットアップ<br className="sp" />価格</a></li>
                        </ul>
                    </div>

                    <div id="s01" className="price__block price01">
                        <div className="inner">
                            <h2 className="price__block__ttl">ICカード価格</h2>
                            <p className="price__block__lead">初めてお申し込みいただく方、継続/追加申込いただく方で価格が異なります。</p>

                            <div className="price__cont cont_1">
                                <h3 className="price__cont__ttl">初めてお申し込みいただく方</h3>
                                <p className="price__cont__lead">名義人が弊社で初めてICカードをお申し込みされる場合に、適用される価格です。</p>
                                <table className="default tbl_1 page-break-inside-avoid">
                                    <thead>
                                        <tr>
                                            <th>有効期間</th>
                                            <th>購入枚数</th>
                                            <th className="blue">価格</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>1年<br className="sp" />(+1ヶ月)</th>
                                            <td>1枚/2枚以上</td>
                                            <td className="blue">9,900円<small className="tax">(税込)</small> × 枚数</td>
                                        </tr>
                                        <tr>
                                            <th rowSpan={2}>2年<br className="sp" />(+1ヶ月)</th>
                                            <td>1枚</td>
                                            <td className="blue">20,680円<small className="tax">(税込)</small></td>
                                        </tr>
                                        <tr>
                                            <td>2枚以上</td>
                                            <td className="blue">19,800円<small className="tax">(税込)</small> × 枚数</td>
                                        </tr>
                                        <tr>
                                            <th>3年<br className="sp" />(+1ヶ月)</th>
                                            <td>1枚/2枚以上</td>
                                            <td className="blue">31,900円<small className="tax">(税込)</small> × 枚数</td>
                                        </tr>
                                        <tr>
                                            <th>4年<br className="sp" />(+1ヶ月)</th>
                                            <td>1枚/2枚以上</td>
                                            <td className="blue">41,800円<small className="tax">(税込)</small> × 枚数</td>
                                        </tr>
                                        <tr>
                                            <th>5年</th>
                                            <td>1枚/2枚以上</td>
                                            <td className="blue">50,600円<small className="tax">(税込)</small> × 枚数</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="price__cont cont_2">
                                <h3 className="price__cont__ttl">同一名義による継続／追加申込の方</h3>
                                <p className="price__cont__lead">ICカードの価格は、以下のとおりとなります。</p>
                                <table className="default tbl_2">
                                    <thead>
                                        <tr>
                                            <th>有効期間</th>
                                            <th>1枚のみ<br className="sp" />ご購入の場合</th>
                                            <th>2枚以上<br className="sp" />ご購入の場合</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>1年<br className="sp" />(+1ヶ月)</th>
                                            <td colSpan={2}>16,500円<small className="tax">(税込)</small> × 枚数</td>
                                        </tr>
                                        <tr>
                                            <th>2年<br className="sp" />(+1ヶ月)</th>
                                            <td>26,180円<small className="tax">(税込)</small><br className="sp" /> × 枚数</td>
                                            <td>22,000円<small className="tax">(税込)</small><br className="sp" /> × 枚数</td>
                                        </tr>
                                        <tr>
                                            <th>3年<br className="sp" />(+1ヶ月)</th>
                                            <td>36,300円<small className="tax">(税込)</small><br className="sp" /> × 枚数</td>
                                            <td>33,000円<small className="tax">(税込)</small><br className="sp" /> × 枚数</td>
                                        </tr>
                                        <tr>
                                            <th>4年<br className="sp" />(+1ヶ月)</th>
                                            <td>46,200円<small className="tax">(税込)</small><br className="sp" /> × 枚数</td>
                                            <td>41,800円<small className="tax">(税込)</small><br className="sp" /> × 枚数</td>
                                        </tr>
                                        <tr>
                                            <th>5年</th>
                                            <td>63,800円<small className="tax">(税込)</small><br className="sp" /> × 枚数</td>
                                            <td>57,200円<small className="tax">(税込)</small><br className="sp" /> × 枚数</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="notice">
                                    <h4>ご注意</h4>
                                    <ul>
                                        <li>表示価格は、すべて1枚あたりの価格です。</li>
                                        <li>「2枚以上」の価格が適用されるのは、ICカード利用申込書の「ICカード発行枚数」の欄に2枚以上をご記入いただいた場合に限ります。</li>
                                        <li>ICカードの有効期間は、ICカード更新の期間を考慮し、通常1年･2年･3年･4年であるところに1ヶ月間を追加して発行しています。<br />
                                            {" "}ただし、有効期間は電子署名法により最長5年としております。<br className="pc" />
                                            {" "}<a href="https://elaws.e-gov.go.jp/document?lawid=413M60000418002" target="_blank" className="blank" rel="noreferrer">電子署名及び認証業務に関する法律施行規則</a>第六条 四「ICカードの有効期間は、五年を超えないものであること。」</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="price__cont cont_3">
                                <h3 className="price__cont__ttl">ICカードの再発行について</h3>
                                <h4 className="price__cont__subTtl">ICカードの再発行価格</h4>
                                <p className="price__cont__txt01">現在ご利用のICカードを失効し、新たにICカードを発行する場合の価格は、以下の計算式に基づき、価格を算出します。なお、再発行の手続きの際に、弊社より割引クーポン券を発行させていただきます。</p>
                                <p className="price__cont__txt02">再発行カード価格●<br className="pc" />{"＝再発行カード通常価格▲－{ 失効カード通常価格◆×（ 失効カード残存有効期間◆／失効カード全有効期間◆ ）}＋発行手数料7,700円(税込)"}</p>
                                <p className="price__cont__txt03">●再発行するICカード1枚あたりの価格です。<br />
                                    ▲再発行するICカード1枚のみご購入の場合の通常価格です。<br />
                                    ◆失効するICカード1枚のみご購入の場合の通常価格です。</p>
                            </div>

                            <div className="price__cont cont_4">
                                <h3 className="price__cont__ttl">情報開示について</h3>
                                <p className="price__cont__txt01">お客さまの証明書内容の情報を開示する場合の価格は、3,300円/件(税込)です。</p>
                            </div>

                        </div>
                    </div>

                    <div id="s02" className="price__block price02">
                        <div className="inner">
                            <h2 className="price__block__ttl">ICカードリーダー価格</h2>

                            <div className="price__cont cont_1">
                                <div className="flexBox">
                                    <div className="flexItem img">
                                        <Picture imgPath="../assets/images/price/price_img_2_1.png" />
                                    </div>
                                    <div className="flexItem textarea">
                                        <p className="price__cont__name">USBタイプ（CLOUD 2700 R）</p>
                                        <div className="price__cont__feature">
                                            <dl className="feature__list">
                                                <dt>特長<em>01</em></dt>
                                                <dd>USBタイプのICカードリーダー</dd>
                                            </dl>
                                            <dl className="feature__list">
                                                <dt>特長<em>02</em></dt>
                                                <dd>軽量タイプで、パソコンからの抜き差しが簡単</dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="price__cont cont_2">
                                <p className="price__cont__txt01">
                                    Web申込書作成ツール「ぷろばてぃお君」を利用する場合と利用しない場合の金額が変わります。</p>
                                <table className="default tbl_3">
                                    <thead>
                                        <tr>
                                            <th>接続<br className="sp" />タイプ</th>
                                            <th>Web申込書作成<br className="sp" />ツール使用する</th>
                                            <th>Web申込書作成<br className="sp" />ツールを使用しない</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>USB</th>
                                            <td>6,600円<small className="tax">(税込)</small><br className="sp" /> × 台数<sup>※1</sup></td>
                                            <td>10,450円<small className="tax">(税込)</small><br className="sp" /> × 台数<sup>※2</sup></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="note count">
                                    <ul>
                                        <li>Web申込書作成ツール「ぷろばてぃお君」を利用しICカードと同時に申し込みする場合、もしくは既にICカードを申し込みいただいている場合の適用価格です。<br />
                                            Web申込書作成ツール「ぷろばてぃお君」は<a href="/web-prob/" target="_blank" className="blank" rel="noreferrer">こちら</a>からアクセス可能です。 </li>
                                        <li>Web申込書作成ツール「ぷろばてぃお君」を利用せず、新規申し込みされた場合の適用価格です。 </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="price__cont cont_3">
                                <div className="notice">
                                    <h4>ご注意</h4>
                                    <ul>
                                        <li>初めてICカードをご利用のお客さまで、ICカードリーダーをお持ちでない場合は必要になります。</li>
                                        <li>動作確認については<a href="/support/icreader">こちら</a>をご参照ください。</li>
                                    </ul>
                                </div>
                                <div className="note">
                                    <ul>
                                        <li>ご購入後6ヶ月以内の故障は、無償で交換いたします。（ただし、お客さまの使用に起因する故障は除きます）</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div id="s03" className="price__block price03">
                        <div className="inner">
                            <h2 className="price__block__ttl">セットアップ価格</h2>
                            <div className="price__cont cont_1">
                                <p className="price__cont__txt01">お使いになる現場まで出張し、パソコンへのソフトのインストールおよびICカードの正常性確認までを実施します。<br />
                                    パソコン操作が苦手な方、電子入札などで初めてパソコンを購入されたお客さまなど、ご検討ください。</p>
                                <table className="default tbl_4">
                                    <thead>
                                        <tr>
                                            <th>派遣費用</th>
                                            <th>セットアップ費用</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>8,800円<small className="tax">(税込)</small><br className="sp" /> × ロケーション数</td>
                                            <td>13,200円<small className="tax">(税込)</small><br className="sp" /> × 端末数</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="note count">
                                    <ul>
                                        <li>一部地域（離島など）については、別途派遣実費をいただく場合がありますので、あらかじめご了承ください。<br />
                                        ※派遣者が現地に伺ってから、お客さまのご都合で、セットアップが実施できなかった場合でも、派遣費用（8,800円(税込)/ロケーション）がかかります。また、お客さまのご都合で、再度派遣する場合も追加で派遣費用（8,800円(税込)/ロケーション）がかかります。</li>
                                        <li>ご利用端末1台（1ロケーション）をセットアップした場合、料金は22,000円(税込)（派遣費用8,800円＋セットアップ費用13,200円）となります。</li>
                                        <li>政府電子調達システム（GEPS）、防衛省装備施設本部（装備）電子入札・開発システム、電子申請・届出システム、電子申告・納税システム（e-Tax）、地方税ポータルシステム（eLTAX）、電子契約システムをご利用の場合のセットアップは受付けておりませんので、あらかじめご了承ください。</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CTAButton
                    leftBtn={{link: "../about", text: "e-Probatio PS2について"}}
                    centerBtn={{link: "../query/first", text: "新規申込方法"}}
                    rightBtn={{link: "../faq", text: "よくあるご質問"}}
                />
                <Breadcrumbs routes={routes} />
            </Body>
        </>
    );
}

export default Price;