export interface Region {
    [index: string]: string[]
}

export const Regions: Region = {
    "北海道": [
        "北海道",
    ],
    "東北": [
        "青森県",
        "岩手県",
        "宮城県",
        "秋田県",
        "山形県",
        "福島県",
    ],
    "関東": [
        "茨城県",
        "栃木県",
        "群馬県",
        "埼玉県",
        "千葉県",
        "東京都",
        "神奈川県",
    ],
    "東海": [
        "岐阜県",
        "静岡県",
        "愛知県",
        "三重県",
    ],
    "北陸／甲信越": [
        "山梨県",
        "新潟県",
        "長野県",
        "富山県",
        "石川県",
        "福井県",
    ],
    "関西": [
        "滋賀県",
        "京都府",
        "大阪府",
        "兵庫県",
        "奈良県",
        "和歌山県",
    ],
    "四国": [
        "徳島県",
        "香川県",
        "愛媛県",
        "高知県",
    ],
    "中国": [
        "鳥取県",
        "島根県",
        "岡山県",
        "広島県",
        "山口県",
    ],
    "九州": [
        "福岡県",
        "佐賀県",
        "長崎県",
        "熊本県",
        "大分県",
        "宮崎県",
        "鹿児島県",
    ],
    "沖縄県": [
        "沖縄県",
    ],
}

export const Area = {
    Prefectures: [
        ...Regions["北海道"],
        ...Regions["東北"],
        ...Regions["関東"],
        ...Regions["東海"],
        ...Regions["北陸／甲信越"],
        ...Regions["関西"],
        ...Regions["四国"],
        ...Regions["中国"],
        ...Regions["九州"],
        ...Regions["沖縄県"],
    ],
    Chuo: "中央省庁",
    KoshaKodan: "公社・公団等",
    Jichitai: "自治体",
    None: "ー"
}

export const Category = {
    BidApplication: ["入札", "申請"],
    TaxPayment: ["納税"],
    eContract: ["電子契約など"]
}

export type System = {
    area?: string,
    category: string,
    priority: number,
    system_name: string,
    url: string,
    remarks: string,
    method: string,
}

export interface SystemsPerPrefecture {
    [index: string]: System[]
}

export interface SystemsPerRegion {
    [index: string]: SystemsPerPrefecture
}
