import { Navigate, Route, Routes } from "react-router-dom";
import Append from "./append/Append";
import Continue from "./continue/Continue";
import ContinueStep from "./continue/ContinueStep";
import First from "./first/First";
import Invalidation from "./invalidation/Invalidation";
import Query from "./Query";
import Reissue from "./reissue/Reissue";
import ReissueStep from "./reissue/ReissueStep";

const QueryRoute = () => {
    return (
    <>
        <Routes>
            <Route path="" element={<Query />} />
            {/* 旧URLからのリダイレクト: お申し込みのご案内 */}
            <Route path="index.html" element={<Navigate to="../" />} />

            <Route path="first" element={<First />} />
            {/* 旧URLからのリダイレクト: 新規の申込方法 */}
            <Route path="first/index.html" element={<Navigate to="../first" />} />
            <Route path="first/step1.html" element={<Navigate to="../first" />} />
            <Route path="first/index2.html" element={<Navigate to="../first" />} />
            <Route path="first/step2.html" element={<Navigate to="../first" />} />
            <Route path="first/step3.html" element={<Navigate to="../first" />} />
            <Route path="first/step4.html" element={<Navigate to="../first" />} />
            <Route path="first/step5.html" element={<Navigate to="../first" />} />

            <Route path="continue">
                <Route path="" element={<Continue />} />
                <Route path="step" element={<ContinueStep />} />
            </Route>
            {/* 旧URLからのリダイレクト: 継続の申込方法 */}
            <Route path="continue/index.html" element={<Navigate to="../continue" />} />
            <Route path="continue/index2.html" element={<Navigate to="../continue/step" />} />
            <Route path="continue/index3.html" element={<Navigate to="../continue/step" />} />
            {/* 旧URLからのリダイレクト: ../継続の申込方法 */}
            <Route path="continue/step1.html" element={<Navigate to="../continue/step" />} />
            <Route path="continue/step2.html" element={<Navigate to="../continue/step" />} />
            <Route path="continue/step3.html" element={<Navigate to="../continue/step" />} />
            <Route path="continue/step4.html" element={<Navigate to="../continue/step" />} />
            <Route path="continue/step5.html" element={<Navigate to="../continue/step" />} />

            <Route path="appnd" element={<Append />} />
            {/* 旧URLからのリダイレクト: 追加の申込方法 */}
            <Route path="appnd/index.html" element={<Navigate to="../appnd" />} />

            <Route path="reissue">
                <Route path="" element={<Reissue />} />
                <Route path="step" element={<ReissueStep />} />
            </Route>
            {/* 旧URLからのリダイレクト: 再発行の申込方法 */}
            <Route path="reissue/index.html" element={<Navigate to="../reissue" />} />
            <Route path="reissue/index2.html" element={<Navigate to="../reissue/step" />} />
            <Route path="reissue/index3.html" element={<Navigate to="../reissue/step" />} />
            {/* 旧URLからのリダイレクト: ../再発行の申込方法 */}
            <Route path="reissue/step1.html" element={<Navigate to="../reissue/step" />} />
            <Route path="reissue/step2.html" element={<Navigate to="../reissue/step" />} />
            <Route path="reissue/step3.html" element={<Navigate to="../reissue/step" />} />
            <Route path="reissue/step4.html" element={<Navigate to="../reissue/step" />} />
            <Route path="reissue/step5.html" element={<Navigate to="../reissue/step" />} />
            <Route path="reissue/step6.html" element={<Navigate to="../reissue/step" />} />

            <Route path="invalidation" element={<Invalidation />} />
            {/* 旧URLからのリダイレクト: 失効の申込方法 */}
            <Route path="invalidation/index.html" element={<Navigate to="../invalidation" />} />
            <Route path="invalidation/step1.html" element={<Navigate to="../invalidation" />} />
            <Route path="invalidation/step2.html" element={<Navigate to="../invalidation" />} />

            {/* Not Found */}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </>
    );
}

export default QueryRoute;
