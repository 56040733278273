import axios from "axios";
import { useEffect, useState } from "react";
import Body from '../components/layout/Body';
import FAQ from "../components/top/FAQ";
import ImportantAnnouncements from "../components/top/ImportantAnnouncements";
import Links, { LinkData } from "../components/top/Link";
import News from "../components/top/News";

const Index = () => {
    document.title = "トップページ | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
    document.querySelector('meta[name="description"]')
        ?.setAttribute(
            "content", 
            "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
        );
    const [links, setLinks] = useState<LinkData>({});
    useEffect(() => {
        axios.get("/cms/json/links.json")
            .then(response => response.data)
            .then((data: LinkData) => {
                setLinks(data);
            });
    }, []);
    return (
        <>
            <Body bodyClass="home">
                <div className="toppage__mv">
                    <div className="toppage__mv__cont">
                        <h1 className="toppage__mv__textarea">
                            <span className="t1">ＮＴＴビジネスソリューションズがお届けする</span>
                            <span className="t2">信頼<small>の</small>電子認証サービス</span>
                            <span className="t3">e-Probatio</span>
                            {links['top_button']
                            && links['top_button'].links.length > 0
                            && links['top_button'].links[0].label !== ""
                            && <span className="link">
                                    <a href={links['top_button'].links[0].url} className="e_btn type02">
                                        {links['top_button'].links[0].label}
                                    </a>
                                </span>
                            }
                        </h1>
                    </div>
                    <figure className="toppage__mv__img01"><img src="/assets/images/toppage/mv_img_1.png" alt="" />
                    </figure>
                    <figure className="toppage__mv__img02"><img src="/assets/images/toppage/mv_img_2.png" alt="" />
                    </figure>
                </div>

                <div className="page-content">

                    <div className="toppage__information">
                        <div className="inner">
                            <div className="flexBox">
                                <ImportantAnnouncements />
                            </div>
                        </div>
                    </div>

                    <div className="toppage__about">
                        <div className="inner">
                            <h2 className="ttl">e-Probatioとは</h2>
                            <div className="textarea">
                                <p>
                                    e-Probatioとは、ＮＴＴビジネスソリューションズが提供する電子認証サービスです。
                                </p>
                                <p>
                                    当サービスは、電子ファイルの世界で、ご利用者さまのビジネスを強力にサポートします。
                                </p>
                                <p>用途別に以下サービスを展開しております。</p>
                            </div>
                            <div className="flexBox">
                                <figure className="img"><img src="/assets/images/toppage/about_img.png" alt="" />
                                </figure>
                                <div className="services">
                                    <div className="service">
                                        <p className="service_title">e-Probatio PS2 サービス</p>
                                        <p>
                                            電子入札・電子契約等にお使いいただける、
                                            <br />
                                            事業者向けの電子証明書（ICカード）を発行いたします。
                                            <br />
                                            <a href="./about">詳しくはこちら（「e-Probatio PS2について」へ）</a>
                                        </p>
                                    </div>

                                    <div className="service">
                                        <p className="service_title">e-Probatio PSA サービス</p>
                                        <p>
                                            税理士向けの電子証明書（ICカード）を発行いたします。
                                            <br />
                                            <a
                                                href="https://www.nichizeiren.or.jp/taxaccount/auth"
                                                className="blank"
                                                target="_blank" rel="noreferrer"
                                            >詳しくはこちら
                                            （日本税理士会連合会ウェブサイトへ）</a>
                                        </p>
                                    </div>

                                    <div className="service">
                                        <p className="service_title">e-Probatio PoA サービス</p>
                                        <p>
                                            マイナンバーカードに代理権を付加する、
                                            <br />
                                            電子委任状を発行いたします。
                                            <br />
                                            <a href="https://poa.e-probatio.com/"
                                            className="blank" target="_blank" rel="noreferrer">詳しくはこちら（PoAサービス専用ウェブサイトへ）</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="toppage__whatsnew">
                        <div className="inner">
                            <News />
                        </div>
                    </div>

                    <div className="toppage__process">
                        <div className="inner">
                        <h2 className="blockTitle">e-Probatio PS2の<br className="sp" />お手続き・関連情報</h2>
                            <div className="flexBox">
                                <div className="img">
                                    <figure><img src="/assets/images/toppage/prosess_img.png" alt="" /></figure>
                                </div>
                                <div className="cont">
                                    <Links link={links['middle_section1']} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="toppage__system">
                        <div className="inner">
                            <div className="flexBox">
                                <div className="img">
                                    <figure><img src="/assets/images/toppage/system_img.png" alt="" /></figure>
                                </div>
                                <div className="cont">
                                    <Links link={links['middle_section2']} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="toppage__faq">
                        <div className="inner">
                            <FAQ />
                        </div>
                    </div>

                    <div className="toppage__banner">
                        <div className="inner">
                            <div className="banner">
                                <a href="https://poa.e-probatio.com/" target="_blank" rel="noreferrer">
                                    <div className="textarea"><h3 className="ttl">
                                        <span>ファイル型</span>の電子委任状サービスはこちら</h3>
                                        <p className="txt">e-Probatio PoA サービス</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </Body>
        </>
    );
};

export default Index;
