export type IntroductionListProps = {
    title: string;
    description: string;
}

/**
 * 導入・ご利用のイメージのリストアイテム
 */
const IntroductionList = (props: {listItems: IntroductionListProps[]}) => {
    const { listItems } = props;
    return (
        <>
            {listItems.map((item, index) => {
                return <IntroductionItem key={index} index={index} title={item.title} description={item.description} />
            })}
        </>
    )
}

const IntroductionItem = (props: {index: number, title: string, description: string}) => {
    const { index, title, description } = props;
    return (<>
        <dl className="introduction__list">
            <dt>STEP<em>{(index + 1).toString().padStart(2, "0")}</em></dt>
            <dd>
                <h3>{ title }</h3>
                <p>{ description }</p>
            </dd>
        </dl>
    </>)
}

export default IntroductionList;
