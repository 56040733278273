import { ReactNode } from "react";

type BodyProps = {
    children: ReactNode;
    bodyClass: string;
}
/**
 * ボディ部で共通使用するコンポーネント
 */
const Body = (props: BodyProps) => {
    const { children, bodyClass } = props;

    return (
        <>
            <div className={ bodyClass }>
                <div className="wrapper">
                    <main className="site-container">
                        <article className="page-container">
                        { children }
                        </article>
                    </main>
                </div>
            </div>
        </>
    );
};

export default Body;
