import React from "react";
import SystemBox from "./SystemBox";
import {System} from "./Types";

const SystemArea = (props: { title: string, systems: System[], showArea?: boolean }) => {
    const getSortedSystems = () => {
        if (props.systems === undefined) {
            return [];
        }

        // priorityの降順でソートする
        return props.systems.concat().sort((a: System, b: System) => {
            return b.priority - a.priority;
        });
    };

    return (
        <>
            {props.systems &&
            <>
                <h3 className="pref_name">{props.title}</h3>
                {getSortedSystems().map((system: System, systemIndex: number) =>
                    <SystemBox system={system} showArea={props.showArea??false} key={systemIndex.toString()}/>
                )}
            </>
            }
        </>
    );
}

export default SystemArea;
