import { useEffect } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import EyeCatch from "../../components/common/EyeCatch";
import Picture from "../../components/common/Picture";
import Body from "../../components/layout/Body";

const Tool = () => {
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/support", breadcrumb: "サポート情報"},
        { path: "/support/tool", breadcrumb: "管理ツールについて"},
    ]
    useEffect(() => {
        document.title = "管理ツールについて | サポート情報 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );

        // 公開情報のCSSが干渉するため、該当CSSのJSでhref属性をクリアしている
        document.getElementById("footer_info")?.setAttribute("href", "")
    }, [])
    return (
    <>
        <Body bodyClass="price">
            <EyeCatch
                title="管理ツールについて"
                description="電子証明書（ICカード）を利用し、電子入札、電子納税（e-Tax・eLTAX）、電子契約、電子申請に参加される場合に必要なソフトウェアのご案内となります。"
            />
                <div className="page-content">

                    <div className="price__block intro">
                            <div className="anchor price__anchor">
                                <ul>
                                    <li><a href="#s01" className="e_btn_anchor">管理ツールとは</a></li>
                                    <li><a href="#s02" className="e_btn_anchor">管理ツール<br className="sp" />ダウンロード</a></li>
                                    <li><a href="#s03" className="e_btn_anchor">管理ツール<br className="sp" />マニュアル</a></li>
                                </ul>
                            </div>
                    </div>

                    <div id="s01" className="price__block price01">
                        <div className="inner">
                            <h2 className="price__block__ttl">管理ツールとは</h2>
                            <p className="price__cont">
                                管理ツールとは、電子証明書（ICカード）を利用・管理するためのクライアントソフトです。
                                本ツールをご利用のパソコンにダウンロードすることで電子入札、電子納税（e-Tax・eLTAX）、電子契約、電子申請にて利用することが可能となります。
                                管理ツールをダウンロードすると「管理メニュー」がデスクトップに作成されます。
                            </p>

                            <div className="price__cont cont_1">
                                <h3 className="price__cont__ttl">「管理メニュー」で実施できる内容</h3>
                                <div className="price__cont">
                                    <Picture figureClass="pc" imgPath="/assets/images/tool/can_do_management_menu.png" />
                                    <Picture figureClass="sp" imgPath="/assets/images/tool/can_do_management_menu_sp.png" />
                                </div>
                            </div>

                            <div className="price__cont cont_3">
                                <h3 className="price__cont__ttl">ICカード取扱のご注意</h3>

                                <h4 className="price__cont__subTtl">暗証番号（PIN）の取り扱いについて</h4>
                                <p className="price__cont__txt01">
                                    電子証明書（ICカード）をご利用になる際は、暗証番号（PIN）を正しく入力してください。<br />
                                    誤った暗証番号（PIN）を連続15回入力された場合は、ICカードが使用不可となります。<br />
                                    ※暗証番号（PIN）が15回未満の間違いであれば、正しい暗証番号（PIN)の入力により、誤入力の回数がクリアとなり再度ICカードを利用することは可能です。<br />
                                    ※2019年12月までに発行しているICカードは暗証番号（PIN）を紛失または誤入力でICカードが使用できなくなった場合は、改めて申込書類の提出と再発行手数料が必要になります。<br />
                                    ※2020年1月以降に発行したICカードは、PINロック解除コード（8桁）を郵送していますので、PINがロックされた場合はPINロック解除コード（8桁）をご利用ください。<br />
                                    　また、PINロック解除コード（8桁）の入力を15回連続で誤入力した場合や紛失された場合は、ICカードが使用できなくなりますので、改めてお申込書類の提出と別途費用が必要になります。<br />
                                    ※なお、ICカード内データおよび暗証番号（PIN）は、ICカード・暗証番号（PIN）を発行後、認証局内で完全削除します。<br />
                                    そのため、同ICカードおよび暗証番号（PIN）を再発行することは一切できませんのであらかじめご了承ください。
                                </p>

                                <h4 className="price__cont__subTtl">記載内容の変更について</h4>
                                <p className="price__cont__txt01">
                                    ICカードの記載情報（ご利用者の姓名、住所、会社名、会社住所）の内容に変更が生じた場合は、ご利用のICカードの失効申込と再発行申込をしていただく必要があります。（別途、費用が必要です。）
                                </p>
                            </div>


                        </div>
                    </div>

                    <div id="s02" className="price__block price02">
                        <div className="inner">
                            <h2 className="price__block__ttl">管理ツールダウンロード</h2>

                            <div className="price__cont cont_2">
                                <p className="price__cont__txt01">
                                    本システムをダウンロードするためにはユーザー名・パスワードが必要となります。<br />
                                    カード郵送時に同封の「管理ツールダウンロードのご案内」をご参照ください。<br />
                                    ※管理ツールのダウンロードを行う場合、下記の同意が必要となります。<br />
                                    <br />
                                    <a href="http://www.cals.jacic.or.jp/coreconso/inadvance/data/CoreRelay_license_agreement_for_bidders.pdf" target="_blank" className="blank" rel="noreferrer" style={{paddingRight:"40px"}}>JACIC（日本建設情報総合センター）の「電子入札補助アプリ使用許諾契約書」に同意 </a>
                                </p>
                                <p className="link"><a href="https://support.e-probatio.com/soft-t2/installer/e-ProbatioPS2.exe" className="e_btn" style={{margin: "30px auto 60px"}}>管理ツールダウンロード</a></p>
                            </div>
                        </div>
                    </div>

                    <div id="s03" className="price__block price03">
                        <div className="inner">
                            <h2 className="price__block__ttl">管理ツールマニュアル</h2>
                            <div className="price__cont cont_1">
                                <p className="price__cont__lead">「管理ツール」の詳細は下記マニュアルをご参照ください。</p>
                                <p className="link"><a href="https://support.e-probatio.com/man/setup-2.pdf" target="_blank" className="e_btn" rel="noreferrer" style={{margin: "30px auto 60px"}}>マニュアルのダウンロード</a></p>
                            </div>
                            <div className="price__cont cont_1">
                                <p className="price__cont__lead">ユーザー名・パスワードが不明の場合はこちらからお問い合わせください。</p>
                                <p className="link"><a href="/contact/" className="e_btn" style={{margin: "30px auto 60px"}}>お問い合わせ</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <Breadcrumbs routes={routes} />
            </Body>
        </>
    );
}

export default Tool;