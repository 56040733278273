import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import "./Cookie.css"

const Cookie = () => {
    const cookieExpiration = '2050-12-31 23:59:59'
    const cookieDate = new Date(cookieExpiration)
    const [cookies, setCookie, removeCookie] = useCookies(["acceptCookie"]);

    const handleAccept = () => {
        setCookie("acceptCookie", true, { expires: cookieDate, path: '/' })
    }

    if (cookies.acceptCookie) return (<></>)
    else return (
        <div className="cookie-consent">
            <p className="cookie-text">当サイトのご利用を続けることにより、Cookieの使用に同意いただいたものとします。
            <br className="sp" />詳しくは、<a href="/sitepolicy">サイトポリシー</a>をご覧ください。</p>
            <a className="cookie-agree" onClick={handleAccept}>閉じる</a>
        </div>
    )
}

export default Cookie;
