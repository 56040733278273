import { useEffect, useMemo, useRef, useState } from "react";
import { NewsData, News } from "./types";
import { categories } from "./const";
import Body from "../layout/Body";
import EyeCatch from "../common/EyeCatch";
import Breadcrumbs from "../common/Breadcrumbs";
import Pagination from "../common/Pagination";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";
import { useQuery } from "../../hooks/useQuery";

const findNewsCategory = (news: News, news_data: NewsData) => {
    return news_data.topics.some(topicNews => topicNews.id === news.id)
            ? categories.find(category => category.key === "topics")
                : news_data.maintenance.some(maintenanceNews => maintenanceNews.id === news.id)
            ? categories.find(category => category.key === "maintenance")
                : news_data.supported_system_information.some(supportNews => supportNews.id === news.id)
            ? categories.find(category => category.key === "supported_system_information")
                : "";
};

/**
 * 新着記事一覧の本体
 */
const NewsListItem = (props: { news: News, news_data: NewsData }) => {
    const { news, news_data } = props;
    const category = findNewsCategory(news, news_data);
    // important_announcementがstringの場合はboolに変換する
    const importantAnnouncement = typeof news.important_announcement === "string" ? JSON.parse(news.important_announcement) : news.important_announcement;

    return (
        <>
            <div className="news__archive__item">
                <div className="head">
                    <div className="meta">
                        <span className="date">{news.publish_date.split(' ')[0]}</span>
                        {
                            news.service.map((service, index) => {
                                return <span key={index} className="tag">{service}</span>
                            })
                        }
                    </div>
                    <span className="categories">
                        <ul>
                            <li>
                                <Link
                                    to={category ? category.link : "/news"}
                                    className={category ? 
                                        category.key === "supported_system_information" ? "system"
                                        : category.key
                                    : ""}
                                >
                                    {category ? category.name : ""}
                                </Link>
                            </li>
                            {importantAnnouncement ? <li className="important">重要なお知らせ</li> : <></>}
                        </ul>
                    </span>
                </div>
                <div className="textarea">
                    <a href={news.format === 'リンク' ? news.link : `${category?.link}/${news.id.toString()}`}>{news.title}</a>
                </div>
            </div>
        </>
    );
}

const NewsSorter = (a: News, b: News) => (new Date(a.publish_date)) < (new Date(b.publish_date)) ? 1 : -1;

/**
 * 新着記事一覧ページ
 */
const NewsList = (props: { news_data: NewsData, defaultSelectedCategory?: keyof NewsData}) => {
    const { news_data, defaultSelectedCategory } = props;
    const [selectedCategory, setSelectedCategory] = useState<keyof NewsData>(defaultSelectedCategory ? defaultSelectedCategory : "all");
    const [viewedYear, setViewedYear] = useState("all");    // 選択中の年度（初期値はすべて）
    const location = useLocation();
    const navigate = useNavigate();
    const { categoryQuery, yQuery, all: allQuery } = useQuery('category', 'y');

    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/news", breadcrumb: "新着情報"},
    ]
    // `すべて`以外はパンくずリストに追加
    if (selectedCategory !== "all"){
        const category = categories.find((item) => item.key === selectedCategory)!;
        routes.push({ path: category.link, breadcrumb: category.name});

    }             

    const titleTagsValue = String(selectedCategory);

    const newsListItems: News[] = useMemo(()=>{
        const tmpItems = news_data[selectedCategory].sort(NewsSorter)
        .filter((news) => {
            const publishYear = news.publish_date.slice(0, 4);
            return publishYear === viewedYear || viewedYear === "all";
        });
        return tmpItems;
    }, [news_data, selectedCategory, viewedYear]);

    // 年度のドロップダウンリスト用データを用意
    const yearsList: string[] = [];
    news_data[selectedCategory].forEach((news) => {
        const publishYear = news.publish_date.slice(0, 4);
        if(yearsList.indexOf(publishYear) === -1) yearsList.push(publishYear);
    });

    const ITEM_PER_PAGE = 10;                                       // iページあたりの表示件数
    const [itemOffset, setItemOffset] = useState(0);                // 表示アイテムの開始位置
    const [currentItems, setCurrentItems] = useState<News[]>([]);   // 現在表示されるリストアイテム
    const [totalPageCount, setTotalPageCount] = useState(1);        // 全体のページ数
    const [currentPageCount, setCurrentPageCount] = useState(0);    // 現在のページ数


    useEffect(() => {
        const endOffset = itemOffset + ITEM_PER_PAGE;
        setCurrentItems(newsListItems.slice(itemOffset, endOffset));
        setTotalPageCount(Math.ceil(newsListItems.length / ITEM_PER_PAGE));
    },[itemOffset, ITEM_PER_PAGE, newsListItems, setSelectedCategory, setViewedYear])

    const setCurrentPage = (pageNumber: number) => {
        setCurrentPageCount(pageNumber);
        const newOffset = (pageNumber * ITEM_PER_PAGE) % newsListItems.length;
        setItemOffset(newOffset);
    }

    const selectCategory = (category: keyof NewsData) => {
        setSelectedCategory(category);
        setCurrentPage(0);
        if (category === "all") setViewedYear("all");
    }

    const selectYear = (year: string) => {
        $('.select__btn__year').click();
        $('.select__btn__category').hasClass('on') && $('.select__btn__category').click();
        setCurrentPage(0);
        navigate(`${location.pathname}?${Object.entries({ ...allQuery, y: year }).map(([k, v]) => `${k}=${v}`).join('&')}`);
    }
    useEffect(() => {
        setViewedYear(yQuery ?? 'all');
    }, [yQuery]);

    // デザイン側で読み込んでいたjQueryのスクリプトをReact内で読み込む
    // (React.StrictModeの場合2回読み込まれるため2回トグルされてしまうので注意)
    const loadedRef = useRef(false);
    useEffect(() => {
        document.title = "新着情報 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );
        if (loadedRef.current === false) {
            $('.select__btn').click(function(){
                $(this).toggleClass('on').next().slideToggle();
            });
        }
        loadedRef.current = true;
    }, [])

    const pageContentRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <Body bodyClass="news">
                <EyeCatch title="新着情報" />
                <div className="page-content" ref={pageContentRef}>
                    <div className="news__archive">
                        <div className="inner">
                            <div className="news__archive__selector">
                                <div className="news__archive__selector__in">
                                    <div className="select__cate">
                                        <div className="select__wrap">
                                            <span className="select__btn select__btn__category">{categories.find((category) => category.key === categoryQuery)?.name ?? 'カテゴリーを選択'}</span>
                                            <ul className="select__list">
                                                {
                                                    categories ? categories.map((category, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Link 
                                                                    to={`${category.link}?category=${category.key}`} 
                                                                    onClick={() => selectCategory(category.key as keyof NewsData)}
                                                                >
                                                                    {category.name}
                                                                </Link>
                                                            </li>
                                                        )
                                                    }) 
                                                    : ""
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="select__datelist">
                                        <div className="select__wrap">
                                            <span className="select__btn select__btn__year">{yQuery ? `${yQuery}年` : '年を選択'}</span>
                                            <ul className="select__list">
                                                {
                                                    yearsList.map((year, index) => {
                                                        return <li key={index} onClick={() => selectYear(year)} style={{ padding: '6px 14px' }}>{year}年</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="news__archive__main">
                                <h2 className="news__archive__title">
                                    {categories ? categories.find((category) => {
                                        return category.key === titleTagsValue 
                                    })?.name + "の新着情報": ""}
                                </h2>
                                <div className="news__archive__list">
                                    {
                                        currentItems.map((news: News, index: number) => {
                                            return <NewsListItem news={news} news_data={news_data} key={index} />
                                        })
                                    }
                                </div>
                                <Pagination
                                    totalPageCount={totalPageCount}
                                    currentPageCount={currentPageCount}
                                    setCurrentPage={setCurrentPage}
                                    resiseObserverTarget={pageContentRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Breadcrumbs routes={routes} />
            </Body>
        </>
    );
}

export {NewsSorter, findNewsCategory};

export default NewsList;
