import { useEffect } from 'react';
import Breadcrumbs from '../../../components/common/Breadcrumbs';
import EyeCatch from '../../../components/common/EyeCatch';
import Body from '../../../components/layout/Body';

const Invalidation = () => {
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/query", breadcrumb: "お申し込みのご案内"},
        { path: "/query/invalidation", breadcrumb: "失効の申込方法"},
    ];
    useEffect(() => {
        document.title = "失効の申込方法 | お申し込みのご案内 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );
    }, [])
    return (
        <>
            <Body bodyClass="queryFirst">
                <EyeCatch
                    title="失効の申込方法"
                    description="登録情報に変更があった、電子証明書（ICカード）を紛失した等で、改めて新しいICカードを<br/>
                        お申し込みされる場合のご案内となります。"
                    imgPath="/assets/images/query/h1_query_invalidation.png"
                />
                <div className="page-content">

                    <div className="queryFirst__block queryFirst__step__intro">
                        <div className="inner">
                            <p>ICカードのご利用を停止させる手続きのご案内となります。</p>
                        </div>
                    </div>



                    <div className="queryFirst__block queryFirst__step__anchor">
                        <div className="inner">
                            <div className="queryFirst__select__contents__box open">
                                <h3 className="box__title">失効のご案内</h3>
                                <p className="step__leadTxt">ICカードのご利用を停止されるお客さまへのご案内です。</p>
                                <h4>1.下のケースは電子署名法に基づき、失効手続きが必要となります。</h4>
                                <div className='queryFirst__step__body'>
                                    <ul>
                                        <li>ICカード及び暗証番号（PIN）の紛失・盗難</li>
                                        <li>暗証番号（PIN）オーバー</li>
                                        <li>ICカードの破損</li>
                                        <li>名義人の変更</li>
                                        <li>名義人の死亡</li>
                                    </ul>
                                </div>
                                <h4>2.ご利用者さまの住所の変更、会社の名称および住所の変更等</h4>
                                <p className="step__leadTxt">
                                    市町村合併等、失効申請・再発行が不要のケースもあります。<br />
                                    まずは各発注機関にお問い合わせください。</p>
                            </div>
                            <div className="queryFirst__select__contents__box open">
                                <h3 className="box__title">申込方法の手順</h3>
                                <p className="step__leadTxt">申込手順と各STEPでの内容は次のようになります。</p>
                            </div>
                            <div className="flexBox">
                                <div className="flexItem">
                                    <a href="#step01" className="anchorBtn">
                                        <div className="step"><span className="flex-align-center"><span>STEP<em className="num">01</em></span></span></div>
                                        <span className="ttl">必要書類の確認と取得</span>
                                    </a>
                                </div>
                                <div className="flexItem">
                                    <a href="#step02" className="anchorBtn">
                                        <div className="step"><span className="flex-align-center"><span>STEP<em className="num">02</em></span></span></div>
                                        <span className="ttl">必要事項の記入／郵送</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="step01" className="queryFirst__block queryFirst__step__block step01">
                        <div className="inner">
                            <div className="queryFirst__step__head">
                                <div className="step__title">
                                    <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">01</em></span></span></div>
                                    <div className="step__title__txt">
                                        <h2 className="ttl">必要書類の確認と取得</h2>
                                    </div>
                                </div>
                                <p className="step__leadTxt">名義人死亡の場合とそれ以外で必要な書類が異なります。</p>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">
                                    <h3 className="box__title">1.名義人死亡による失効の場合</h3>
                                    <div className='queryFirst__step__head'>
                                        <p className="step__leadTxt">失効申込書（ボタンをクリックすると「ICカード失効申込書兼届出書」のPDFを表示します）。</p>
                                        <div>
                                            <p className="link2_1"><a href="/download/ps2-revoke.pdf" rel='noreferrer' target="_blank" className="e_btn type_blank" style={{margin: "30px auto"}}>ICカード失効申込書<br />兼届出書（PDF）</a></p>
                                        </div>
                                        <p className="step__leadTxt">本人死亡を確認できる物(下記のいずれか一点)</p>
                                        <ul>
                                            <li>戸籍謄本(原本)</li>
                                            <li>戸籍抄本(原本)</li>
                                            <li>戸籍全部事項証明書(原本)</li>
                                            <li>戸籍個人事項証明書(原本)</li>
                                            <li>履歴事項全部証明書(原本)</li>
                                            <li>死亡診断書の写し</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="queryFirst__step__body">
                                <h3 className="box__title">2.その他の場合</h3>
                                <div className='queryFirst__step__head'>
                                    <p className="step__leadTxt">失効申込書のみ（ボタンをクリックすると「ICカード失効申込書兼届出書」のPDFを表示します）。</p>
                                    <div>
                                        <p className="link2_1"><a href="/download/ps2-revoke.pdf" rel='noreferrer' target="_blank" className="e_btn type_blank" style={{margin: "30px auto"}}>ICカード失効申込書<br />兼届出書（PDF）</a></p>
                                    </div>
                                </div>
                            </div>
                            
                            <a href="#step02" className="queryFirst__nextstep e_btn_anchor sp">次へ</a>
                        </div>
                    </div>

                    <div id="step02" className="queryFirst__block queryFirst__step__block step02">
                        <div className="inner">
                            <div className="queryFirst__step__head">
                                <div className="step__title">
                                    <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">02</em></span></span></div>
                                    <div className="step__title__txt">
                                        <h2 className="ttl">必要事項の記入／郵送</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">
                                    <h3 className="box__title">ご記入される際の注意</h3>
                                    <ul>
                                        <li>押印箇所はすべてお申し込み時と同一（個人の）の実印を押印ください。</li>
                                        <li>同一内容のICカードを複数枚、同時に失効される際は、シリアル番号記入欄に失効される分すべてのシリアル番号をご記入ください。</li>
                                        <li>シリアル番号はICカードの表面に記載されている16桁の英数字です。</li>
                                        <li>退社等で押印できない際は代印として法人印を押印し、法人印鑑登録証明書を同封の上、ご郵送ください。</li>
                                    </ul>

                                </div>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">
                                    <h3 className="box__title">ご郵送される前に</h3>
                                    <p style={{marginTop: "18px"}}>
                                        弊社では失効申込書兼届出書が弊社に到着しだい、直ちに失効処理（ICカードが利用できない状態）を行います。<br />
                                        発注機関によっては 更新時に古いカードが必要な場合もありますので、失効時期等、各発注機関にお問い合わせの後、充分ご注意の上、ご郵送ください。<br />
                                        以上で失効申請の手順は完了いたします。
                                    </p>

                                </div>
                            </div>
                            <div className="queryFirst__step__body">
                                <div className="inner">
                                    <h3 className="box__title">送付先</h3>
                                    <p>〒530-0003<br />
                                        大阪市北区堂島3-1-21 ＮＴＴデータ堂島ビル16階<br />
                                        ＮＴＴビジネスソリューションズ株式会社<br />
                                        バリューデザイン部　<br className="sp" />
                                        トラストビジネス担当
                                    </p>
                                    <p className="fs-13 c-e72929">※申込方法に関して、ご不明な点は<br className="sp" />下のリンクからご参照ください。</p>
                                    <p className="link2_1"><a href="../faq" className="e_btn type03" style={{marginTop: "20px", width: "400px"}}>よくあるご質問</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Breadcrumbs routes={routes} />
            </Body>
        </>
    );
}

export default Invalidation;