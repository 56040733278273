import { Routes, Route } from "react-router-dom";
import { NewsData } from "../../../components/news/types";
import NewsPage from "../NewsPage";
import NewsList from "../../../components/news/NewsList";

const MaintenanceRoute = (props: {news_data: NewsData}) => {
    return (
    <>
        <Routes>
            <Route
                path=""
                element={
                    <NewsList
                        news_data={props.news_data}
                        defaultSelectedCategory="maintenance"
                    />
                }
            />
            <Route 
                path={"/:newsId"}
                element={
                    <NewsPage
                        newsList={props.news_data.maintenance}
                        category="maintenance"
                    />
                }
            />
        </Routes>
    </>
    );
}

export default MaintenanceRoute;