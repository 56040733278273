import { useEffect } from "react";
import Body from "../../components/layout/Body";
import EyeCatch from "../../components/common/EyeCatch";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { Link } from "react-router-dom";

const SitePolicy = () => {
    const routes = [
        {path: "/", breadcrumb: "e-Probatio トップ"},
        {path: "/sitepolicy", breadcrumb: "サイトポリシー"},
    ];
    useEffect(() => {
        document.title = "サイトポリシー | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "このウェブサイト（以下、「本サイト」といいます。）は、ＮＴＴビジネスソリューションズ株式会社（以下、「当社」といいます。）が運営する電子認証サービス「e-Probatio PS2」の提供を主な目的としております"
            );
    }, [])
    return (
        <>
            <Body bodyClass="sitepolicy">
                <EyeCatch title="サイトポリシー" />
                        <div className="page-content">
                            <div className="sitepolicy__block">
                            <div className="inner">
                                <h4 className="sitepolicy__cont__subTtl">
                                ウェブサイトご利用にあたってのお願い（ご利用条件）
                                </h4>
                                <p className="sitepolicy__cont__txt01">
                                このウェブサイト（以下、「本サイト」といいます。）は、ＮＴＴビジネスソリューションズ株式会社（以下、「当社」といいます。）が運営するe-Probatio認証局公式サイトであり、電子認証サービス「e-Probatio
                                PS2」の提供を主な目的としております。<br />本サイトのご利用にあたっては、事前に以下のご利用条件をお読み頂き、これらの条件にご同意の上ご利用ください。
                                </p>
                                <h4 className="sitepolicy__cont__subTtl">ブラウザー環境について</h4>
                                <p className="sitepolicy__cont__txt01">
                                本サイトは、<Link to={{ pathname: "/support/", hash: "#s01"}} className="blank" target="_blank" rel="noreferrer">こちら</Link>
                                のシステム推奨環境でご覧ください。<br /><small
                                    >※推奨環境以外でご利用いただいた場合や、推奨環境下でもお客さまのブラウザーの設定等によっては、正しく表示されない場合がありますのでご了承ください。</small
                                >
                                </p>
                                <h4 className="sitepolicy__cont__subTtl">
                                個人情報の取扱いについて
                                </h4>
                                <p className="sitepolicy__cont__txt01">
                                当社は、プライバシーポリシーに従って、お客さまの個人情報を適正に取り扱うことをお約束いたします。<br />プライバシーポリシーについては<a
                                    target="_blank"
                                    className="blank"
                                    href="https://www.nttbizsol.jp/company/policy/"
                                    rel="noreferrer"
                                    >こちら</a
                                >をご参照ください。
                                </p>
                                <h4 className="sitepolicy__cont__subTtl">
                                スクリプト（JavaScript）の使用について
                                </h4>
                                <p className="sitepolicy__cont__txt01">
                                本サイトではお客さまにより便利にサイトをご利用いただけるよう、JavaScriptを使用しております。<br />JavaScriptを無効にして使用された場合、コンテンツが正常に動作しないおそれやページが表示されない場合がございます。本サイトご利用の際には、JavaScriptを有効にしていただきますようお願いいたします。
                                </p>
                                <h4 className="sitepolicy__cont__subTtl">クッキー（Cookie）について</h4>
                                <p className="sitepolicy__cont__txt01">
                                本サイトでは、お客さまがサイトをご利用される上で必要なセッションの維持、利便性の向上、お客さまのアクセス動向の把握による使いやすいWebサイトの実現、などを目的として、クッキー（Cookie）を使用しておりますが、お客さまのプライバシーを侵害するものではなく、またお客さまのコンピューターへ悪影響を及ぼすことはありません。<br />
                                インターネット閲覧ソフト（ブラウザ）の設定により、クッキー（Cookie）の受け取りを拒否することも可能ですが、その場合は、当サイトでのサービスのお申し込み、お問い合わせ等の一部を正しく閲覧・操作することができない場合があります。<br />
                                ブラウザの設定方法は各ソフト製造元へお問い合わせください。
                                    
                                </p>
                                <h4 className="sitepolicy__cont__subTtl">
                                SSL（暗号化通信）の使用について
                                </h4>
                                <p className="sitepolicy__cont__txt01">
                                本サイトでは、お客さまの個人情報など保護が必要なデータを安全にやりとりするため、SSL(Secure
                                Socket
                                Layer)を利用した暗号化通信を使用しています。本サイトのウェブサーバとお客さまがお使いのブラウザー間の情報はSSL技術を使って保護されています。
                                </p>
                                <h4 className="sitepolicy__cont__subTtl">リンクサイトについて</h4>
                                <p className="sitepolicy__cont__txt01">
                                本サイトから、または本サイトへリンクを張っている当社以外の第三者のウェブサイト（以下「リンクサイト」といいます。）は、それぞれのリンクサイトの責任において運営・管理されており、お客さまがこれらのリンクサイトをご利用する際は、それぞれのリンクサイトに掲載されているご利用条件に沿ってご利用ください。<br />各リンクサイトの内容およびそれらのリンクサイトの利用により生じる一切の損害について、当社は、いかなる責任も負いません。
                                </p>
                                <h4 className="sitepolicy__cont__subTtl">免責について</h4>
                                <ol>
                                <li>
                                    当社は、本サイトに掲載されている全ての情報を慎重に作成し、また管理しますが、その正確性および完全性などに関して、いかなる保証もするものではありません。
                                </li>
                                <li>
                                    当社は、システムの保守・障害、火災・停電・地震等の天災、その他運用・技術上、本サイトの情報提供の一時中断が必要であると判断した場合には、事前予告なしに、本サイトの運営を停止または中止し、また本サイトに掲載されている情報の全部または一部を変更する場合があります。
                                </li>
                                <li>
                                    お客さまが本サイトをご利用されたこと、または何らかの原因によりこれをご利用できなかったことによりお客さまに生じる一切の損害および第三者によるデータの書き込み、不正なアクセス、発言、メールの送信等に関して生じる一切の損害（お客さま同士の間でトラブルが生じ、これによりお客さまが被る損害を含みます。）について、当社は、何ら責任を負うものではありません。
                                </li>
                                </ol>
                                <h4 className="sitepolicy__cont__subTtl">商標について</h4>
                                <p className="sitepolicy__cont__txt01">
                                本サイトで使用される商標（トレードマークおよびサービスマーク）は、当社または当社に使用を認めた権利者に帰属しますので、無断で使用することはできません。
                                </p>
                            </div>
                            </div>
                        </div>
                <Breadcrumbs routes={routes}/>
            </Body>
        </>
    );
}

export default SitePolicy;
