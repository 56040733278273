import Body from '../../components/layout/Body';
import EyeCatch from "../../components/common/EyeCatch";
import CTAButton from "../../components/common/CTAButton";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { useEffect } from "react";

const About = () => {
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/about", breadcrumb: "e-Probatio PS2について"},
    ]
    useEffect(() => {
        document.title = "e-Probatio について | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')?.setAttribute("content", "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio");
    }, [])
    return (
    <>
        <Body bodyClass="about">
            <EyeCatch
                title="e-Probatio PS2について"
                description="当ウェブサイトで主に取り扱うe-Probatio
                PS2サービスの概要をご紹介いたします。"
                imgPath="/assets/images/about/mv_img.png"
            />
            <div className="page-content">

                <div className="anchor">
                    <ul>
                        <li><a href="#s01" className="e_btn_anchor">e-Probatio PS2<br className="sp" />サービスとは</a></li>
                        <li><a href="#s02" className="e_btn_anchor">e-Probatio PS2<br className="sp" />以外のサービス</a></li>
                    </ul>
                </div>

                <div id="s01" className="about__block about01">
                    <div className="inner">
                        <h2 className="ttl">e-Probatio PS2サービスとは</h2>
                        <div className="cont_1">
                            <div className="flexBox">
                                <div className="flexItem">
                                    <h3 className="subTtl">電子証明書発行サービス</h3>
                                    <p className="t1">例えば電子入札などで、入札の文章が、確かにそれを作成した本人のものと相違ないかどうかを確認する仕組みが<span className="marker">電子認証</span>です。<br />
                                        <span className="marker">電子認証は「電子署名」と「電子証明書」のペア</span>で成立します。<br />
                                        そして利用者の電子署名（公開鍵）が本人に帰属していることを証明するために認証局が発行する電子的な証明書が電子証明書です。<br />
                                        e-Probatio PS2はその<span className="marker">ICカードを発行するサービス</span>です。</p>
                                </div>
                                <div className="flexItem">
                                    <figure className="img_1"><img src="/assets/images/about/about_img_1_1.png" alt=""/></figure>
                                </div>
                            </div>
                        </div>
                        <p className="t2">公共発注機関および入札参加者さまに対し、電子入札を行うために必要となる電子証明書です。SI事業を中核に多くの実績と信頼を獲得してきたＮＴＴビジネスソリューションズがご提供する「e-Probatio PS2サービス」が、お客さまのビジネスを強力にサポートします。<br />
                            「e-Probatio PS2サービス」は、「電子署名及び認証業務に関する法律」（平成13年4月1日施行）で定められた特定認証業務の認定を取得。<br />
                            セキュリティ性・信頼性の高い電子証明書を発行しています。</p>
                        <div className="cont_2">
                            <div className="flexBox">
                                <div className="flexItem">
                                    <div className="serviceBox">
                                        <figure className="serviceBox_img_1"><img src="/assets/images/about/about_img_1_2.png" alt="" /></figure>
                                        <div className="textarea">
                                            <p className="serviceBox_t1">電子認証サービス「e-Probatio PS2」は、平成13年4月1日に施行された「電子署名及び認証業務に関する法律」に基づく特定認証業務の認定を取得しております。</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flexItem">
                                    <div className="serviceBox">
                                        <figure className="serviceBox_img_2"><img src="/assets/images/about/about_img_1_3.png" alt="" /></figure>
                                        <div className="textarea">
                                            <p className="serviceBox_t2">電子認証サービス「e-Probatio PS2」は、<a href="https://www.jacic.or.jp/jacic-hp/index.html" target="_blank" className="blank" rel="noreferrer">一般財団法人日本建設情報総合センター</a>および一般財団法人港湾空港建設技術サービスセンターが募集した「電子入札コアシステム対応認証局」です。</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about__block about02">
                    <div className="inner">
                        <div className="cont_1">
                            <div className="flexBox">
                                <div className="flexItem">
                                    <div className="box">
                                        <figure><img src="/assets/images/about/about_img_2_1.png" alt="" /></figure>
                                        <h3 className="ttl">e-Probatio PS2の特長</h3>
                                        <p className="t1">ICカードを利用して、パソコンから電子入札や電子申請などが行えます。</p>
                                        <p className="link"><a href="/about/feature" className="e_btn">e-Probatio PS2の特長を見る</a></p>
                                    </div>
                                </div>
                                <div className="flexItem">
                                    <div className="box">
                                        <figure><img src="/assets/images/about/about_img_2_2.png" alt="" /></figure>
                                        <h3 className="ttl">導入・ご利用のイメージ</h3>
                                        <p className="t1">ICカードのお申し込みから利用方法のご案内となります。</p><br />
                                        <p className="link"><a href="/about/introduction" className="e_btn">導入・ご利用のイメージを見る</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cont_2">
                            <div className="flexBox">
                                <div className="img">
                                    <figure><img src="/assets/images/about/about_img_2_3.png" alt="" /></figure>
                                </div>
                                <div className="textarea">
                                    <h3 className="ttl">システム対応マップ</h3>
                                    <p className="t1">e-Probatio
                                                    PS2がご利用いただける発注システムの一覧です。</p>
                                    <p className="link"><a href="/about/system" className="e_btn">システム対応マップを見る</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="s02" className="about__block about03">
                    <div className="inner">
                        <h2 className="ttl">e-Probatio PS2以外のサービス</h2>
                        <p className="lead">以下の通り、さまざまな用途に向けた電子認証サービスを展開しております。</p>
                        <div className="cont_1">
                            <div className="flexBox">
                                <div className="flexItem">
                                    <div className="box">
                                        <h3 className="subTtl">e-Probatio PSA サービス</h3>
                                        <div className="explanation">
                                            <p className="t1">
                                                税理士向けの電子証明書（ICカード）を発行いたします。現在、日本税理士会連合会を通して提供中のサービスです。 
                                            </p>
                                            <p className="t1">
                                                ※お申し込みやお問い合わせについては、<br />以下リンク先からお願いします。
                                            </p>
                                        </div>
                                        <p className="link"><a href="https://www.nichizeiren.or.jp/taxaccount/auth/" target="_blank" className="e_btn type_blank" rel="noreferrer">日本税理士会連合会</a></p>
                                    </div>
                                </div>
                                <div className="flexItem">
                                    <div className="box">
                                        <h3 className="subTtl">e-Probatio PoA サービス</h3>
                                        <div className="explanation">
                                            <p className="t1">
                                                電子証明書に代理権を追加する電子委任状を発行いたします。現在、マイナンバーカードに対して政府電子調達システム（GEPS）に向けたサービスを提供しています。
                                            </p>
                                            <p className="t1">
                                                ※お申し込みやお問い合わせについては、以下リンク先からお願いします。
                                            </p>

                                        </div>
                                        <p className="link"><a href="https://poa.e-probatio.com/" target="_blank" className="e_btn type_blank" rel="noreferrer">PoAサービス専用サイト</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CTAButton
                leftBtn={{link: "../../query/first", text: "新規申込方法"}}
                centerBtn={{link: "../../price", text: "価格について"}}
                rightBtn={{link: "../../faq", text: "よくあるご質問"}}
            />
            <Breadcrumbs routes={routes} />
        </Body>
    </>
    );
}

export default About;