import { Navigate, Route, Routes } from "react-router-dom";
import Campaign from "./Campaign";
import Price from "./Price";

const PriceRoute = () => {
    return (
    <>
        <Routes>
            <Route path="" element={<Price />} />
            {/* 旧URLからのリダイレクト: 価格について */}
            <Route path="index.html" element={<Navigate to="../" />} />

            <Route path="campaign" element={<Campaign />} />
            {/* 旧URLからのリダイレクト: 割引・キャンペーン情報 */}
            <Route path="campaign.html" element={<Navigate to="../campaign" />} />

            {/* Not Found */}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>

    </>
    );
}

export default PriceRoute;
