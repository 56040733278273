import { Navigate, Route, Routes } from "react-router-dom";
import About from "./About";
import Feature from "./Feature";
import Introduction from "./Introduction";
import SystemMap from "./SystemMap";

const AboutRoute = () => {
    return (
    <>
        <Routes>
            <Route path="" element={<About />} />
            {/* 旧URLからのリダイレクト: e-Probatio PS2について */}
            <Route path="index.html" element={<Navigate to=".." />} />

            <Route path="feature" element={<Feature />} />
            {/* 旧URLからのリダイレクト: e-Probatio PS2の特長 */}
            <Route path="merit.html" element={<Navigate to="../feature" />} />

            <Route path="introduction" element={<Introduction />} />
            {/* 旧URLからのリダイレクト: 導入・ご利用のイメージ */}
            <Route path="introduction.html" element={<Navigate to="../introduction" />} />

            <Route path="system" element={<SystemMap />} />
            {/* 旧URLからのリダイレクト: システム対応マップ */}
            <Route path="system/index.html" element={<Navigate to="../system" />} />


            {/* その他パスに`/about`がつく旧URLからのリダイレクト */}
            {/* (旧)セミナー講師派遣 -> トップページ */}
            <Route path="instructor.html" element={<Navigate to="/" />} />
            {/* (旧)パンフレットの送付 -> トップページ */}
            <Route path="pamphlet.html" element={<Navigate to="/" />} />
            {/* (旧)サービス・価格 -> 価格について */}
            <Route path="price/index.html" element={<Navigate to="/price" />} />
            {/* (旧)電子証明書（ICカード）の価格 -> 価格について */}
            <Route path="price/iccard.html" element={<Navigate to="/price" />} />
            {/* (旧)ICカードリーダーの価格 -> 価格について */}
            <Route path="price/reader.html" element={<Navigate to="/price" />} />
            {/* (旧)セットアップサービス -> サポート情報/システムセットアップ */}
            <Route path="price/setupservice.html" element={<Navigate to="/support/setup" />} />
            {/* (旧)割引情報 -> サポート情報/システムセットアップ */}
            <Route path="price/campaign.html" element={<Navigate to="/price/campaign" />} />
            {/* (旧)ICカード取扱のご注意 -> サポート情報/管理ツールについて */}
            <Route path="caution.html" element={<Navigate to="/support/tool" />} />
            {/* (旧)動作確認済みICカードリーダー -> サポート情報/ICカードリーダーについて */}
            <Route path="confirmedreader.html" element={<Navigate to="/support/icreader" />} />
            {/* (旧)推奨環境 -> サポート情報/ICカードリーダーについて */}
            <Route path="environment.html" element={<Navigate to="/support/icreader" />} />
            {/* (旧)関連情報/協力会社 -> 関連情報 */}
            <Route path="link.html" element={<Navigate to="/themes" />} />
            {/* (旧)関連情報/関連法令 -> 関連情報 */}
            <Route path="horei.html" element={<Navigate to="/themes" />} />
            {/* (旧)関連情報/サービス関連団体 -> 関連情報 */}
            <Route path="dantai.html" element={<Navigate to="/themes" />} />
            {/* (旧)推奨環境 -> サポート情報 */}
            <Route path="other.html" element={<Navigate to="/support" />} />

            {/* Not Found */}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </>
    );
}

export default AboutRoute;
