import { CategoryItem } from "./types"

export const categories: CategoryItem[] = [
    {
        key: "all",
        name: "すべて",
        link: "/news",
    },
    {
        key: "topics",
        name: "TOPICS",
        link: "/news/topics",
    },
    {
        key: "maintenance",
        name: "メンテナンス情報",
        link: "/news/maintenance",
    },
    {
        key: "supported_system_information",
        name: "対応システム情報",
        link: "/news/system",
    },
];
