import {IntroductionListProps} from "./IntroductionList";

export const introductionListItems: IntroductionListProps[] = [
    {
        title: "電子証明書発行のお申し込み",
        description: "ご利用者さま（電子入札参加資格者さま等）から認証局へ電子証明書の発行を依頼していただきます。",
    },
    {
        title: "電子証明書の発行（ICカード）",
        description: "認証局はご利用申込者さまの本人性を審査した上で、電子証明書をICカードに格納してご利用者さまに郵送します。",
    },
    {
        title: "ICカードを使って電子入札に参加",
        description: "ご利用者さまはICカードを使うことで、電子入札などのインターネットを介した取引を安心して行うことができます。",
    },
    {
        title: "電子証明書の有効性確認",
        description: "公共発注機関または電子入札参加資格者さまと認証局の三者間で、電子証明書の有効性確認を実施します。",
    },
    {
        title: "入札結果参照",
        description: "入札結果を参照します。",
    },
];
