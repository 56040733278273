import { useEffect, useState } from "react";
import axios from "axios";
import NewsList from "../../components/news/NewsList";
import { NewsData } from "../../components/news/types";

const News = () => {
    const [newsData, setNewsData] = useState<NewsData>({
        all: [],
        important_announcement: [],
        topics: [],
        maintenance: [],
        supported_system_information:[]}
    );
    useEffect(() => {
        axios.get("/cms/json/news/data.json")
            .then(response => response.data)
            .then((data: NewsData) => {
                setNewsData(data)
            });
    }, [])

    return <NewsList news_data={newsData}></NewsList>
}

export default News;
