import { useEffect } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import EyeCatch from "../../components/common/EyeCatch";
import Picture from "../../components/common/Picture";
import Body from "../../components/layout/Body";

const Setup = () => {
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/support", breadcrumb: "サポート情報"},
        { path: "/support/setup", breadcrumb: "システムセットアップ"},
    ]
    useEffect(() => {
        document.title = "システムセットアップ | サポート情報 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );
    }, [])
    return (
    <>
        <Body bodyClass="price">
            <EyeCatch
                title="システムセットアップ"
                description="電子証明書（ICカード）をご利用いただくセットアップ方法のご案内となります。"
            />
                <div className="page-content">

                    <div className="price__block intro">
                        <div className="inner">
                            <p className="leadTxt">
                                ICカードをご利用いただくために、お使いのパソコンにセットアップ作業が必要です。<br />
                                初めてICカードを購入される方と継続や追加で購入された方のセットアップ方法が異なります。<br />
                                該当する項目内容をご確認のうえ、手順に従ってセットアップ作業をしてください。<br />
                                弊社ではセットアップ作業を代行するサービス（有料オプション）も用意しております。<br />
                                パソコン操作が苦手な方や初めて電子入札をされるお客さまなど、ご検討ください。
                            </p>
                        </div>
                    </div>

                    <div className="anchor price__anchor">
                        <ul>
                            <li><a href="#s01" className="e_btn_anchor">初めてのセットアップ</a></li>
                            <li><a href="#s02" className="e_btn_anchor">追加・継続の<br className="sp" />セットアップ</a></li>
                            <li><a href="#s03" className="e_btn_anchor">セットアップ<br className="sp" />サービス</a></li>
                        </ul>
                    </div>

                    <div id="s01" className="price__block price01">
                        <div className="price__block intro">
                            <div className="inner">
                                <h2 className="price__block__ttl">初めてのセットアップ</h2>
                            </div>
                        </div>
                        <div id="step01" className="queryFirst__block queryFirst__step__block step01">
                            <div className="inner">
                                <div className="queryFirst__step__head">
                                    <div className="step__title">
                                        <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">01</em></span></span></div>
                                        <div className="step__title__txt">
                                            <h2 className="ttl">物品の確認<br className="sp" />（弊社より購入）</h2>
                                        </div>
                                    </div>
                                    <p className="step__leadTxt">下記3点をお手元にご準備ください。</p>
                                </div>
                                <div className="queryFirst__step__body">
                                    <div className="inner">
                                        <div className="queryFirst__attention">
                                            <ul>
                                                <li>ICカード</li>
                                                <li>ICカードリーダ</li>
                                                <li>「暗証番号（PIN）・ロック解除コード」が記載されているハガキ</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="step02" className="queryFirst__block queryFirst__step__block step02">
                            <div className="inner">
                                <div className="queryFirst__step__head">
                                    <div className="step__title">
                                        <div className="step__title__num"><span className="flex-align-center"><span>STEP<em className="num">02</em></span></span></div>
                                        <div className="step__title__txt">
                                            <h2 className="ttl">セットアップ<br className="sp" />（ソフトのインストール）</h2>
                                        </div>
                                    </div>
                                    <p className="step__leadTxt">
                                        電子システムを利用する際に必要なソフトは、<a href="/support/tool">こちら</a>よりインストールできます。<br />
                                        また、管理ツールをインストールするにはユーザー名・パスワードが必要です。<br />
                                        ユーザー名・パスワードがご不明な方は弊社<a href="/contact/">お問い合わせフォーム</a>よりご連絡ください。
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="s02" className="price__block price02">
                        <div className="price__block intro">
                            <div className="inner">
                                <h2 className="price__block__ttl">追加・継続のセットアップ</h2>
                            </div>
                        </div>
                        <div className="queryFirst__step__body">
                            <div className="inner">
                                <div className="queryFirst__contents__box">
                                    <h3 className="box__title">証明書内容確認方法・委任項目確認方法</h3>
                                    <p>このセットアップ案内は継続して電子証明書を取得されたお客さま、追加の電子証明書を取得されたお客さまへのご案内です。<br />
                                        「管理ツール」のダウンロードがまだの方は弊社TOPページ「管理ツールダウンロードはこちらをクリック」からインストールしてください。<br />
                                        すでにセットアップが完了している方は次の手順のとおりです。</p>
                                </div>

                                <div className="cont_1">
                                    <div className="flexBox">
                                        <div className="flexItem img">
                                            <Picture imgPath="/assets/images/setup/add_continue_setup.png" />
                                        </div>
                                        <div className="flexItem">
                                            <p className="queryFirst__attention">デスクトップに「e-Probatio PS2
                                                管理メニュー」のショートカットが作成されます。<br /><br />①「電子証明書内容確認・動作確認」から証明書の内容をご確認していただけます。<br /><br />②「委任項目確認」から登録している委任内容をご確認いただけます。
                                            </p>
                                            {/* 2022-11-06 
                                                CSSがデザインと同一でも行高に差異が発生する(CSSをまとめて読み込んでいる影響？)ため、
                                                ここだけ要素へ直接スタイルを追記している
                                            */}
                                            <div className="notice" style={{lineHeight: "normal"}}>
                                                <small>
                                                    ※「委任項目確認」はGoogle Chrome非対応になりますのでご注意ください。<br />
                                                    詳しい手順は<a href="https://support.e-probatio.com/man/setup-2.pdf">マニュアル</a>「4.管理メニューについて」をご参照ください。
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="s03" className="price__block price03">
                                <h2 className="price__block__ttl">セットアップサービス</h2>
                        <div className="price__block intro">
                            <div className="inner">
                                <p className="leadTxt">
                                    お使いになる現場まで出張し、パソコンへのソフトのインストールおよびICカードの正常性確認までを実施します。<br />
                                    パソコン操作が苦手な方、電子入札などで初めてパソコンを購入されたお客さまなど、ご検討ください。
                                </p>
                            </div>
                        </div>
                        <div className="queryFirst__step__body">
                            <div className="inner">
                                <div className="queryFirst__contents__box">
                                    <h3 className="box__title">セットアップサービス価格表</h3>
                                    <table className="default tbl_1">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>費用</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>派遣費用</th>
                                                <td>8,800円(税込)/ロケーション</td>
                                            </tr>
                                            <tr>
                                                <th>セットアップ費用</th>
                                                <td>13,200円(税込)/端末</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div>
                                        <ul>
                                            <small><li>※一部地域（離島など）については、別途派遣実費をいただく場合がありますので、あらかじめご了承ください。</li></small>
                                            <small><li>※派遣者が現地に伺ってから、お客さまのご都合で、セットアップが実施できなかった場合でも、派遣費用（8,800円(税込)/ロケーション）がかかります。また、お客さまのご都合で、再度派遣する場合も追加で派遣費用（8,800円(税込)/ロケーション）がかかります。</li></small>
                                            <small><li>※ご利用端末1台（1ロケーション）をセットアップした場合、料金は22,000円(税込)（派遣費用8,800円＋セットアップ費用13,200円）となります。</li></small>
                                            <small>
                                                <li>※政府電子調達システム（GEPS）、防衛省装備施設本部（装備）電子入札・開発システム、電子申請・届出システム、電子申告・納税システム（e-Tax）、地方税ポータルシステム（eLTAX）、電子契約システムをご利用の場合のセットアップは受付けておりませんので、あらかじめご了承ください。
                                                </li>
                                            </small>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="queryFirst__step__body">
                            <div className="inner">
                                    <div className="queryFirst__contents__box">
                                        <h3 className="box__title">セットアップサービス内容</h3>
                                        <table className="default">
                                            <thead>
                                                <tr>
                                                    <th colSpan={2}>作業内容</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>同封物の確認</td>
                                                    <td>
                                                        ・同封物品の確認<br />
                                                        ・セットアップ準備
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>環境設定</td>
                                                    <td>・電子入札専用システムのインストール</td>
                                                </tr>
                                                <tr>
                                                    <td>接続試験</td>
                                                    <td>
                                                        ・回線検証<br />
                                                        ・ICカード機能検証 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>完了確認</td>
                                                    <td>
                                                        ・パソコン周辺の環境検証<br />
                                                        ・完了報告書へのサインまたは押印 
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>
                <Breadcrumbs routes={routes} />
            </Body>
        </>
    );
}

export default Setup;
