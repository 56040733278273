/**
 * ページトップにスクロールするボタン
 * @returns 
 */
const PageTopButton = () => {
    return (
		<p id="pagetop">
            <a href="#"></a>
        </p>
    );
}

export default PageTopButton;
