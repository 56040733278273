import React from "react";
import {System} from "./Types";


const SystemBox = (props: { system: System, showArea: boolean }) => {
    return (
        <>
            <div className="tblWrap">
                <table className="system__tender__group__tbl">
                    <tbody>
                    <tr>
                        <th>発注機関／<br className="sp"/>システム名称</th>
                        <td>
                            {props.system.url !== '#'
                                ? <a href={props.system.url}
                                    target="_blank"
                                    className="blank" rel="noreferrer">
                                    {props.system.system_name}
                                </a>
                                : props.system.system_name
                            }
                        </td>
                    </tr>
                    { props.showArea &&
                    <tr>
                        <th>エリア</th>
                        <td>{props.system.area}</td>
                    </tr>
}
                    <tr>
                        <th>種別</th>
                        <td>{props.system.category}</td>
                    </tr>
                    <tr>
                        <th>備考</th>
                        <td dangerouslySetInnerHTML={{__html: props.system.remarks}}/>
                    </tr>
                    <tr>
                        <th>方式</th>
                        <td>{props.system.method}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default SystemBox;
