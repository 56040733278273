import { useEffect } from "react";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import CTAButton from "../../components/common/CTAButton";
import EyeCatch from "../../components/common/EyeCatch";
import Body from "../../components/layout/Body";
import useHashLink from "../../hooks/useHashLink";

const Support = () => {
    useHashLink();
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/support", breadcrumb: "サポート情報"},
    ]
    useEffect(() => {
        document.title = "サポート情報 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "電子入札などでは、電子証明書が必須となります。ご利用いただくには、ICカードリーダーも必要になります。パソコンへのソフトウェアのインストールおよびICカードの機能検証などを行うセットアップサービス（有料）もご用意しております。"
            );
    }, [])
    return (
        <>
            <Body bodyClass="support">
                <EyeCatch
                    title="サポート情報"
                    description="電子証明書（ICカード）やICカードリーダーを利用するために必要なソフトウェアを紹介しています。"
                    imgPath=""
                />
                <div className="page-content">
                    <div className="support__block support__intro">
                        <div className="inner">
                            <p>
                                パソコンで、ICカード等をご利用いただく場合は、必ずソフトウェアのインストールが必要です。
                            </p>
                        </div>
                    </div>
                    <div className="anchor">
                        <ul>
                            <li>
                                <a href="#s01" className="e_btn_anchor">
                                    推奨環境
                                </a>
                            </li>
                            <li>
                                <a href="#s02" className="e_btn_anchor">
                                    各種サポート情報
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div
                        id="s01"
                        className="support__block support__pages support01"
                    >
                        <div className="inner">
                            <h2 className="support__block__ttl">推奨環境</h2>
                            <p className="support__block__lead">
                                「e-Probatio
                                PS2」で発行するICカードは、主に電子入札コアシステムでご利用いただくためのものです。
                                <br />
                                電子入札コアシステムをご利用いただく際には、以下の要件を満たすシステム環境が必要です。
                                <br />
                                また、要件を満たしている場合でも、ご利用環境によりパソコンが正常に動作しないことがありますので、
                                <br />
                                電子入札システム専用端末をご用意いただくことをおススメいたします。
                            </p>
                            <div className="support__select__buttons">
                                <div className="flexBox">
                                    <div className="flexItem">
                                        <button
                                            className="selectBtn selectBtn_1 on"
                                            data-select="s1-1"
                                        >
                                            ハードウェア
                                        </button>
                                    </div>
                                    <div className="flexItem">
                                        <button
                                            className="selectBtn selectBtn_1"
                                            data-select="s1-2"
                                        >
                                            ソフトウェア
                                        </button>
                                    </div>
                                    <div className="flexItem">
                                        <button
                                            className="selectBtn selectBtn_1"
                                            data-select="s1-3"
                                        >
                                            ネットワーク
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="support__select__contents">
                                <div
                                    id="s1-1"
                                    className="support__select__contents__box selectBox_1 open"
                                >
                                    <div className="cont">
                                        <h4 className="ttl">パソコン</h4>
                                        <table className="default tbl_1 page-break-inside-avoid">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Windows 10</th>
                                                    <th>Windows 11</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>CPU</th>
                                                    <td colSpan={3}>
                                                        Core Duo
                                                        1.6GHz同等かそれ以上
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>メモリ</th>
                                                    <td>
                                                        1.0GB以上(32bit版)
                                                        <br />
                                                        2.0GB以上(64bit版)
                                                    </td>
                                                    <td>4.0GB以上</td>
                                                </tr>
                                                <tr>
                                                    <th>HDD</th>
                                                    <td colSpan={3}>
                                                        1ドライブの空き容量
                                                        <br />
                                                        1.0G以上
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>その他</th>
                                                    <td colSpan={3}>
                                                        USB-Aポート
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="cont">
                                        <h4 className="ttl">
                                            ICカードリーダー
                                        </h4>
                                        <p className="txt">
                                            ICカードリーダーを接続するために、パソコンに利用可能なUSB-Aポートが１つ必要です。
                                            <br />
                                            パソコン本体のUSB-AポートにICカードリーダーを直接接続して使用してください。
                                            <br />
                                            ICカードリーダーは接触型のみ利用可能です。（非接触型は非対応）
                                            <br />
                                            動作確認済みのICカードリーダーは、
                                            <a
                                                href="../support/icreader/"
                                                className="link"
                                            >
                                                こちら
                                            </a>
                                            のページでご参照ください。
                                        </p>
                                    </div>
                                    <div className="support__note">
                                        <ul>
                                            <li>
                                                <span>※</span>Windows は米国
                                                Microsoft Corporation
                                                の米国およびその他の国における商標または登録商標です。
                                            </li>
                                            <li>
                                                <span>※</span>Core Duo
                                                はアメリカ合衆国およびその他の国における
                                                Intel Corporation
                                                またはその子会社の商標または登録商標です。
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    id="s1-2"
                                    className="support__select__contents__box selectBox_1"
                                >
                                    <div className="cont">
                                        <h4 className="ttl">ソフトウェア</h4>
                                        <table className="default tbl_2">
                                            <tbody>
                                                <tr>
                                                    <th>OS</th>
                                                    <td>
                                                        Windows 10
                                                        (32bit版・64bit版)
                                                        <br />
                                                        Home
                                                        <br />
                                                        Pro
                                                    </td>
                                                    <td>
                                                        Windows 11 (64bit版)
                                                        <br />
                                                        Home
                                                        <br />
                                                        Pro
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Webブラウザ</th>
                                                    <td>
                                                        Microsoft Edge
                                                        (Chromium版)
                                                        <br />
                                                        Google Chrome
                                                    </td>
                                                    <td>
                                                        Microsoft Edge
                                                        (Chromium版)
                                                        <br />
                                                        Google Chrome
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>.NET Framework</th>
                                                    <td>4.6.2以降</td>
                                                    <td>4.8以降</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="cont">
                                        <h4 className="ttl">例外事項</h4>
                                        <div className="notice">
                                            <ul>
                                                <li>
                                                    委任項目確認は、Google
                                                    Chromeには対応しておりませんので、Microsoft
                                                    Edgeをご利用ください。
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="cont">
                                        <h4 className="ttl">その他</h4>
                                        <div className="notice">
                                            <ul>
                                                <li>
                                                    セットアップする際のログインユーザは、管理者権限が必要です。
                                                </li>
                                                <li>
                                                    電子入札システム等をご利用の際も管理者権限が必要な場合があります。
                                                </li>
                                                <li>
                                                    ご利用になる電子入札システムの推奨環境にも合わせる必要がございますのでご注意ください。
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="support__note">
                                        <ul>
                                            <li>
                                                <span>※</span>Windows は米国
                                                Microsoft Corporation
                                                の米国およびその他の国における商標または登録商標です。
                                            </li>
                                            <li>
                                                <span>※</span>Core Duo
                                                はアメリカ合衆国およびその他の国における
                                                Intel Corporation
                                                またはその子会社の商標または登録商標です。
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    id="s1-3"
                                    className="support__select__contents__box selectBox_1"
                                >
                                    <h4 className="ttl">ネットワーク</h4>
                                    <div className="cont">
                                        <h4 className="ttl">回線</h4>
                                        <p className="txt">
                                            インターネットに接続できる環境が必要です。
                                            <br />
                                            快適な操作を行うために、高速回線による常時接続をおススメいたします。
                                        </p>
                                    </div>
                                    <div className="cont">
                                        <h4 className="ttl">プロトコル</h4>
                                        <p className="txt">
                                            主に電子入札を実施される場合、「HTTP,
                                            HTTPS,
                                            LDAP」の各プロトコルを使用します。プロバイダもしくは社内のシステム管理者にご参照ください。
                                            <br />
                                            インターネット上で、電子メールが送受信できるようにしてください。
                                            <br />
                                            社内LAN環境からご利用いただく際も、上記プロトコルがファイアウォールを通過できることをご参照ください。
                                            <br />
                                            LDAP通信が通過しているかどうかの試験は、
                                            <a
                                                href="/faq/#faq2_2957"
                                            >
                                                こちら
                                            </a>
                                            をご参照ください。
                                            <br />
                                            弊社のシステム接続ソフトは、LDAP
                                            Proxyに接続するための機能を有しておりません。ご了承ください。
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id="s02"
                        className="support__block support__pages support02"
                    >
                        <div className="inner">
                            <h2 className="support__block__ttl">
                                各種サポート情報
                            </h2>
                            <div className="flexBox">
                                <div className="flexItem">
                                    <div className="support__item">
                                        <figure>
                                            <img
                                                src="../assets/images/support/img1.jpg"
                                                alt=""
                                            />
                                        </figure>
                                        <div className="txtarea">
                                            <h3 className="ttl">
                                                管理ツールについて
                                            </h3>
                                            <p className="txt">
                                                ICカードを利用し、電子入札、電子納税（e-Tax・eLTAX）、電子契約、電子申請に参加される場合に必要なソフトウェアのご案内となります。
                                            </p>
                                            <p className="link">
                                                <a
                                                    href="../support/tool"
                                                    className="e_btn"
                                                >
                                                    管理ツールについてを見る
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flexItem">
                                    <div className="support__item">
                                        <figure>
                                            <img
                                                src="../assets/images/support/img2.jpg"
                                                alt=""
                                            />
                                        </figure>
                                        <div className="txtarea">
                                            <h3 className="ttl">
                                                ICカードリーダーについて
                                            </h3>
                                            <p className="txt">
                                                動作確認済みICカードリーダーとソフトウェアのご案内となります。
                                                <br />
                                                <br />
                                            </p>
                                            <p className="link">
                                                <a
                                                    href="../support/icreader"
                                                    className="e_btn"
                                                >
                                                    ICカードリーダーについてを見る
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flexItem">
                                    <div className="support__item">
                                        <figure>
                                            <img
                                                src="../assets/images/support/img3.jpg"
                                                alt=""
                                            />
                                        </figure>
                                        <div className="txtarea">
                                            <h3 className="ttl">
                                                システムセットアップ
                                            </h3>
                                            <p className="txt">
                                                ICカードをご利用いただくセットアップ方法のご案内となります。
                                            </p>
                                            <p className="link">
                                                <a
                                                    href="../support/setup"
                                                    className="e_btn"
                                                >
                                                    システムセットアップを見る
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CTAButton
                    leftBtn={{ link: "/query/first", text: "新規申込方法" }}
                    centerBtn={{ link: "/price", text: "価格について" }}
                    rightBtn={{ link: "/faq", text: "よくあるご質問" }}
                />
                <Breadcrumbs routes={routes} />
            </Body>
        </>
    );
}

export default Support;