import { useEffect } from "react";
import { useLocation } from "react-router-dom";

let timeout: NodeJS.Timeout;

/**
 * 別ページからリンク遷移後に該当IDに遷移するため、一度アンカーリンクをクリアして付け直すフック
 * 別ページからアンカーリンクで参照されているページにて利用する
 */
const useHashLink = () => {
	const location = useLocation();
	useEffect(() => {
		seachAndJump(location.hash);
	}, [location.hash])
}

/**
 * ページレンダリング直後は hash で指定された要素が存在しない場合があるので、100ms ごとに 30 回まで検索し、見つけたら飛ぶようにする
 */
const seachAndJump = (hash: string, n = 0) => {
	if (n > 30) return;

	if (!hash.length) return;
	
	clearTimeout(timeout);
	timeout = setTimeout(() => {
		const element = document.body.querySelector(hash);
		if (!element) {
			seachAndJump(hash,n + 1);
			return;
		}

		const scrollY = element.getBoundingClientRect().y;
		if (scrollY < 100) return;
		
		const siteHeaderHeight = document.querySelector<HTMLDivElement>('.site-header')?.offsetHeight ?? 0;
		// window.location.href 
		window.scrollTo(0, element.getBoundingClientRect().y - siteHeaderHeight);
	}, 100);
}

export default useHashLink;