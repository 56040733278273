import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useResizeObserver } from "../../hooks/useResizeObserver";


/**
 * ページネーション
 */
const Pagination = (props: {
    totalPageCount: number,
    currentPageCount: number,
    setCurrentPage: (pageNumber: number) => void,
    resiseObserverTarget: React.MutableRefObject<Element | undefined | null>,
}) => {
    const { totalPageCount, currentPageCount, setCurrentPage, resiseObserverTarget } = props;
    // forcePageを介して自作した forward および backward と react-paginate とをリンクさせる
    const [forcePage, setForcePage] = useState(0);
    //
    const [, setLastY] = useState(0);
    const pagenaviRef = useRef<HTMLDivElement>(null);

    // ページが初期位置となっていれば初期ページに戻す
    useEffect(() => {
        if (currentPageCount === 0) setForcePage(0);
    }, [currentPageCount]);

    // ページ数をクリックした時に該当ページへ遷移する
    const handlePageClick = (e: {selected: number}) => {
        const selectedPage = e.selected;
        setForcePage(selectedPage);
        setCurrentPage(selectedPage);
        setLastY(pagenaviRef.current?.getBoundingClientRect().y ?? 0);
    }

    // << をクリックした時最初のページに飛ぶ
    const handleToStartClick = (e: React.MouseEvent) => {
        e.preventDefault();
        setForcePage(0);
        setCurrentPage(0);
        setLastY(pagenaviRef.current?.getBoundingClientRect().y ?? 0);
    }
    
    // >> をクリックした時最後のページに飛ぶ
    const handleToEndClick = (e: React.MouseEvent) => {
        e.preventDefault();
        setForcePage(totalPageCount - 1);
        setCurrentPage(totalPageCount - 1);
        setLastY(pagenaviRef.current?.getBoundingClientRect().y ?? 0);
    }

    const onResizeStageWrapper = useCallback((rect: DOMRectReadOnly) => {
        setLastY((lastY) => {
            const currentY = pagenaviRef.current?.getBoundingClientRect().y ?? 0;
            if (window.scrollY === 0 && lastY === 0) return lastY;
            window.scrollTo(0, window.scrollY + currentY - lastY);
            return currentY;
        });
    }, []);
    useResizeObserver(resiseObserverTarget, onResizeStageWrapper);

    const width = useMemo(() => document.body.offsetWidth, []);
    
    return (
        <div className="pagenavi" ref={pagenaviRef}>
            <div className="inner">
                <a 
                    className="backword"
                    role="button"
                    onClick={(e) => handleToStartClick(e)}
                    href=""
                >
                </a>
                <ReactPaginate
                    pageRangeDisplayed={width > 420 ? 5 : width > 350 ? 3 : 1}
                    marginPagesDisplayed={width > 620 ? 3 : 0}
                    pageCount={totalPageCount}
                    previousLabel=""
                    nextLabel=""
                    previousLinkClassName="prev"
                    nextLinkClassName="next"
                    onPageChange={handlePageClick}
                    activeLinkClassName="current"
                    containerClassName="pages"
                    forcePage={forcePage}
                />
                <a
                    className="forword"
                    role="button"
                    onClick={(e) => handleToEndClick(e)}
                    href=""
                >
                </a>

            </div>
        </div>
    );
}

export default Pagination;
