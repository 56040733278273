import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router';
export const useQuery = <T extends string[]>(..._targetQueries: T) => {
  const { current: targetQueries } = useRef(_targetQueries);
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const retrunQueries = useMemo(() => {
    return targetQueries.reduce(
      (queries, targetQueryString) => ({ ...queries, [`${targetQueryString}Query`]: query.get(targetQueryString) }),
      {} as Record<`${typeof targetQueries[number]}Query`, string | null>
    );
  }, [query, targetQueries]);
  const all = useMemo(() => Object.fromEntries(query.entries()), [query]);
  return { ...retrunQueries, all };
};
