import { useEffect } from "react";
import Body from "../../components/layout/Body";
import EyeCatch from "../../components/common/EyeCatch";
import Breadcrumbs from "../../components/common/Breadcrumbs";

const Themes = () => {
    const routes = [
        {path: "/", breadcrumb: "e-Probatio トップ"},
        {path: "/themes", breadcrumb: "関連情報"},
    ];
    useEffect(() => {
        document.title = "関連情報 | サポート情報 | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );
    }, [])
    return (
        <>
            <Body bodyClass="queryFirst">
                <EyeCatch title="関連情報" />
                <div className="page-content">

                    <div className="queryFirst__block queryFirst__step__block step01">
                        <div className="queryFirst__step__body">
                            <div className="inner">

                                <div className="queryFirst__select__buttons">
                                    <div className="flexBox">
                                        <div className="flexItem">
                                            <button className="selectBtn selectBtn_1 on" data-select="s1-1">協力会社
                                            </button>
                                        </div>
                                        <div className="flexItem">
                                            <button className="selectBtn selectBtn_1" data-select="s1-2">関連法令</button>
                                        </div>
                                        <div className="flexItem">
                                            <button className="selectBtn selectBtn_1" data-select="s1-3">サービス関連団体
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="queryFirst__select__contents">

                                    <div id="s1-1" className="queryFirst__select__contents__box selectBox_1 open">
                                        西日本電信電話株式会社<br/>
                                        フレッツ光<br/>
                                        (販売協力)<br/>
                                        <a target="_blank"
                                           className="blank"
                                           href="http://flets-w.com/" rel="noreferrer">http://flets-w.com/</a><br/>
                                        &nbsp;<br/>
                                        日本電通株式会社<br/>
                                        (セットアップ対応)<br/>
                                        <a target="_blank"
                                           className="blank"
                                           href="https://www.ndknet.co.jp/" rel="noreferrer">https://www.ndknet.co.jp/</a>
                                    </div>

                                    <div id="s1-2" className="queryFirst__select__contents__box selectBox_1">
                                        総務省<br/>
                                        電子署名・電子認証<br/>
                                        <a target="_blank"
                                           className="blank"
                                           href="http://www.soumu.go.jp/main_sosiki/joho_tsusin/top/ninshou-law/law-index.html" rel="noreferrer">http://www.soumu.go.jp/main_sosiki/joho_tsusin/top/ninshou-law/law-index.html</a><br/>
                                        &nbsp;<br/>
                                        法務省 民事局<br/>
                                        電子署名法の概要と認定制度について<br/>
                                        <a target="_blank"
                                           className="blank"
                                           href="http://www.moj.go.jp/MINJI/minji32.html" rel="noreferrer">http://www.moj.go.jp/MINJI/minji32.html</a><br/>
                                        &nbsp;<br/>
                                    </div>

                                    <div id="s1-3" className="queryFirst__select__contents__box selectBox_1 ">
                                        一般財団法人日本建設情報総合センター（JACIC）<br/>
                                        電子入札コアシステム開発コンソーシアム<br/>
                                        <a target="_blank"
                                           className="blank"
                                           href="http://www.cals.jacic.or.jp/coreconso/index.html" rel="noreferrer">http://www.cals.jacic.or.jp/coreconso/index.html</a><br/>
                                        &nbsp;<br/>
                                        総務省 行政管理局<br/>
                                        政府認証基盤（GPKI）<br/>
                                        <a target="_blank"
                                           className="blank"
                                           href="http://www.gpki.go.jp/" rel="noreferrer">http://www.gpki.go.jp/</a><br/>
                                        &nbsp;<br/>
                                        一般財団法人 日本情報処理開発協会<br/>
                                        電子署名・認証センター（JIPDEC）<br/>
                                        <a target="_blank"
                                           className="blank"
                                           href="https://www.jipdec.or.jp/" rel="noreferrer">https://www.jipdec.or.jp/</a><br/>
                                        &nbsp;<br/>
                                        電子認証局会議（CAC）<br/>
                                        <a target="_blank"
                                           className="blank"
                                           href="http://www.c-a-c.jp/" rel="noreferrer">http://www.c-a-c.jp/</a>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <Breadcrumbs routes={routes}/>
            </Body>
        </>
    );
}

export default Themes;