import React from "react";
import QuestionTitle from "./Question";
import Answers from "./Answer";
import {File} from "../common/types"

export type Question = {
    [index: string]: QuestionCategories
}

export type QuestionCategories = {
    [index: string]: QuestionRow[]
}

export type QuestionRow = {
    id: Number;
    service: string[];
    show_on_top: boolean;
    priority: string;
    question: string;
    answers: Answer[];
    file: File;
    modified_date: string;
    publish_date: string;
}

export type Answer = {
    answer: string;
    image: string;
    caption: string;
    alt: string;
}

export const ExclusionCategoryNames: string[] = [
    "使用しない",
]


const QuestionTab = (props: { question: QuestionRow, questionIndex: Number }) => {
    return (
        <>
            <QuestionTitle question={props.question.question} questionIndex={props.questionIndex} />
            <Answers answers={props.question.answers}
                     file={props.question.file}
                     questionIndex={props.questionIndex} />
        </>
    );
}

export default QuestionTab;
