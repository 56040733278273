import { useEffect } from 'react';
import Breadcrumbs from "../../components/common/Breadcrumbs";
import EyeCatch from "../../components/common/EyeCatch";
import Body from "../../components/layout/Body";

const SiteMap = () => {
    const routes = [
        { path: "/", breadcrumb: "e-Probatio トップ"},
        { path: "/sitemap", breadcrumb: "サイトマップ"},
    ]
    useEffect(() => {
        document.title = "サイトマップ | e-Probatio ＮＴＴビジネスソリューションズの電子認証サービス";
        document.querySelector('meta[name="description"]')
            ?.setAttribute(
                "content", 
                "ＮＴＴビジネスソリューションズがお届けする、信頼の電子認証サービス e-Probatio"
            );
    }, [])
    return (
        <>
            <link rel="stylesheet" href="../assets/css/about.css" media="all" />
            <Body bodyClass="about">
                <EyeCatch
                    title="サイトマップ"
                />
                <div className="page-content">
                    <div className="anchor sitemap__block pc">
                        <div className="sitemap__cont">
                            <ul>
                                <li className="layer1">
                                    <h3><a href="/" className="e_btn_arw">e-Probatioトップページ</a></h3>
                                </li>
                            </ul>
                        </div>
                        <div className="sitemap__cont">
                            <ul>
                                <li className="layer1">
                                    <h3><a href="/about" className="e_btn_arw">e-Probatio PS2について</a></h3>
                                    <ul>
                                        <li className="layer2"><a href="/about/feature" className="e_btn_arw">e-Probatio PS2の特長</a></li>
                                        <li className="layer2"><a href="/about/introduction" className="e_btn_arw">導入・ご利用のイメージ</a></li>
                                        <li className="layer2"><a href="/about/system" className="e_btn_arw">システム対応マップ</a></li>
                                    </ul>
                                </li>
                                <li className="layer1">
                                    <h3><a href="/price" className="e_btn_arw">価格について</a></h3>
                                    <ul>
                                        <li className="layer2"><a href="/price/#s01" className="e_btn_arw">ICカード価格</a></li>
                                        <li className="layer2"><a href="/price/#s02" className="e_btn_arw">ICカードリーダー価格</a></li>
                                        <li className="layer2"><a href="/price/#s03" className="e_btn_arw">セットアップ価格</a></li>
                                    </ul>
                                </li>
                                <li className="layer1">
                                    <h3><a href="/faq" className="e_btn_arw">よくあるご質問</a></h3>
                                    <ul>
                                        <li className="layer2"><a href="/faq/#faq01" className="e_btn_arw">e-Probatio PS2 お申し込みに関するご質問</a></li>
                                        <li className="layer2"><a href="/faq/#faq02" className="e_btn_arw">e-Probatio PS2 セットアップ・システムに関するご質問</a></li>
                                    </ul>
                                </li>
                                <li className="layer1">
                                    <h3><a href="/footer/info" className="e_btn_arw">公開情報</a></h3>
                                </li>
                            </ul>
                        </div>
                        <div className="sitemap__cont">
                            <ul>
                                <li className="layer1">
                                    <h3><a href="/query" className="e_btn_arw">お申し込みのご案内</a></h3>
                                    <ul>
                                        <li className="layer2"><a href="/query/first" className="e_btn_arw">新規の申込方法</a></li>
                                        <li className="layer2"><a href="/query/continue" className="e_btn_arw">継続の申込方法</a></li>
                                        <li className="layer2"><a href="/query/appnd" className="e_btn_arw">追加の申込方法</a></li>
                                        <li className="layer2"><a href="/query/reissue" className="e_btn_arw">再発行の申込方法</a></li>
                                        <li className="layer2"><a href="/query/invalidation" className="e_btn_arw">失効の申込方法</a></li>
                                    </ul>
                                </li>
                                <li className="layer1">
                                    <h3><a href="/support" className="e_btn_arw">サポート情報</a></h3>
                                    <ul>
                                        <li className="layer2"><a href="/support/tool" className="e_btn_arw">管理ツールについて</a></li>
                                        <li className="layer2"><a href="/support/icreader" className="e_btn_arw">ICカードリーダーについて</a></li>
                                        <li className="layer2"><a href="/support/setup" className="e_btn_arw">システムセットアップ</a></li>
                                    </ul>
                                </li>
                                <li className="layer1">
                                    <h3><a href="/news" className="e_btn_arw">新着情報</a></h3>
                                </li>
                                <li className="layer1">
                                    <h3 className="no-arrow">その他</h3>
                                    <ul>
                                        <li className="layer2"><a href="/web-prob/" className="e_btn_arw">申し込み書作成ツール「ぷろばてぃお君」</a></li>
                                        <li className="layer2"><a href="/contact/" className="e_btn_arw">お問い合わせ</a></li>
                                        <li className="layer2"><a href="/sitepolicy" className="e_btn_arw">サイトポリシー</a></li>
                                        <li className="layer2"><a href="/themes" className="e_btn_arw">関連情報</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="anchor sitemap__block sp">
                        <ul>
                            <li className="layer1">
                                <h3><a href="/" className="e_btn_arw">e-Probatioトップページ</a></h3>
                            </li>
                            <li className="layer1">
                                <h3><a href="/about" className="e_btn_arw">e-Probatio PS2について</a></h3>
                                <ul>
                                    <li className="layer2"><a href="/about/feature" className="e_btn_arw">e-Probatio PS2の特長</a></li>
                                    <li className="layer2"><a href="/about/introduction" className="e_btn_arw">導入・ご利用のイメージ</a></li>
                                    <li className="layer2"><a href="/about/system" className="e_btn_arw">システム対応マップ</a></li>
                                </ul>
                            </li>
                            <li className="layer1">
                                <h3><a href="/query" className="e_btn_arw">お申し込みのご案内</a></h3>
                                <ul>
                                    <li className="layer2"><a href="/query/first" className="e_btn_arw">新規の申込方法</a></li>
                                    <li className="layer2"><a href="/query/continue" className="e_btn_arw">継続の申込方法</a></li>
                                    <li className="layer2"><a href="/query/appnd" className="e_btn_arw">追加の申込方法</a></li>
                                    <li className="layer2"><a href="/query/reissue" className="e_btn_arw">再発行の申込方法</a></li>
                                    <li className="layer2"><a href="/query/invalidation" className="e_btn_arw">失効の申込方法</a></li>
                                </ul>
                            </li>
                            <li className="layer1">
                                <h3><a href="/price" className="e_btn_arw">価格について</a></h3>
                                <ul>
                                    <li className="layer2"><a href="/price/#s01" className="e_btn_arw">ICカード価格</a></li>
                                    <li className="layer2"><a href="/price/#s02" className="e_btn_arw">ICカードリーダー価格</a></li>
                                    <li className="layer2"><a href="/price/#s03" className="e_btn_arw">セットアップ価格</a></li>
                                </ul>
                            </li>
                            <li className="layer1">
                                <h3><a href="/support" className="e_btn_arw">サポート情報</a></h3>
                                <ul>
                                    <li className="layer2"><a href="/support/tool" className="e_btn_arw">管理ツールについて</a></li>
                                    <li className="layer2"><a href="/support/icreader" className="e_btn_arw">ICカードリーダーについて</a></li>
                                    <li className="layer2"><a href="/support/setup" className="e_btn_arw">システムセットアップ</a></li>
                                </ul>
                            </li>
                            <li className="layer1">
                                <h3><a href="/faq" className="e_btn_arw">よくあるご質問</a></h3>
                                <ul>
                                    <li className="layer2"><a href="/faq/#faq01" className="e_btn_arw">e-Probatio PS2 お申し込みに関するご質問</a></li>
                                    <li className="layer2"><a href="/faq/#faq02" className="e_btn_arw">e-Probatio PS2 セットアップ・システムに関するご質問</a></li>
                                </ul>
                            </li>
                            <li className="layer1">
                                <h3><a href="/news" className="e_btn_arw">新着情報</a></h3>
                            </li>
                            <li className="layer1">
                                <h3><a href="/footer/info" className="e_btn_arw">公開情報</a></h3>
                            </li>
                            <li className="layer1">
                                <h3 className="no-arrow">その他</h3>
                                <ul>
                                    <li className="layer2"><a href="/web-prob/" className="e_btn_arw">申し込み書作成ツール「ぷろばてぃお君」</a></li>
                                    <li className="layer2"><a href="/contact/" className="e_btn_arw">お問い合わせ</a></li>
                                    <li className="layer2"><a href="/sitepolicy" className="e_btn_arw">サイトポリシー</a></li>
                                    <li className="layer2"><a href="/themes" className="e_btn_arw">関連情報</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>

                <Breadcrumbs routes={routes} />
            </Body>
        </>
    );
}

export default SiteMap;