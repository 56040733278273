import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { NewsData } from "../../components/news/types";
import MaintenanceRoute from "./maintenance/MaintenanceRoute";
import News from "./News";
import SystemRoute from "./system/SystemRoute";
import TopicsRoute from "./topics/TopicsRoute";

const NewsRoute = () => {
    const [newsData, setNewsData] = useState<NewsData>({ all: [], important_announcement: [], topics: [], maintenance: [], supported_system_information: [] });
    useEffect(() => {
        axios.get("/cms/json/news/data.json")
            .then(response => response.data)
            .then((data: NewsData) => {
                setNewsData(data)
            });
    }, [])

    return (
    <>
        <Routes>
            <Route path="" element={<News />} />
            <Route path="topics/*" element={<TopicsRoute news_data={newsData} />} />
            <Route path="maintenance/*" element={<MaintenanceRoute news_data={newsData} />} />
            <Route path="system/*" element={<SystemRoute news_data={newsData} />} />


            {/* パスに`/news`がつく旧URLからのリダイレクト */}
            {/* (旧)新着情報/詳細ページ -> 新着情報 */}
            <Route path="detail.html" element={<Navigate to=".." />} />
            {/* (旧)重要なお知らせ -> 新着情報 */}
            <Route path="important.html" element={<Navigate to=".." />} />
            {/* (旧)メンテナンス情報 -> 新着情報/TOPICS */}
            <Route path="topics.html" element={<Navigate to="../topics" />} />
            {/* (旧)メンテナンス情報 -> 新着情報/メンテナンス情報 */}
            <Route path="maintenance.html" element={<Navigate to="../maintenance" />} />
            {/* (旧)対応システム情報 -> 新着情報/対応システム情報 */}
            <Route path="system.html" element={<Navigate to="../system" />} />

            {/* Not Found */}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </>
    );
}

export default NewsRoute;
