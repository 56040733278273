import { Link } from "react-router-dom";


export type NavGroupProps = {
    prefix?: string;
    title: string;
    url: string;
    navSubItems: NavSubItem[];
    element: string;
    isSubSp?: boolean;
}

export type NavSubItem = {
    title: string;
    url: string;
}

/**
 *  サブグループを含んだナビアイテム
 * @param props 
 * @returns 
 */
const NavGroup = (props: NavGroupProps) => {
    const { prefix, title, url, navSubItems, element, isSubSp } = props;
    return (
        <>
            <li>
                {element === "header" ? (
                    <>
                        <span className="js-gmenuBtn">
                            { prefix ? <em className="sp_large">{prefix}</em> : ""}{title}
                        </span>
                    </>
                ) : (
                    <>
                        <Link to={url} className="pc_large">{title}</Link>
                        <span className="js-gmenuBtn sp_large">{title}</span>
                    </>
                )}
                <ul className={isSubSp === true ? "sub sp_large" : "sub"}>
                    <li>
                        {
                            element === "header" ? (
                                <a href={url}><span>{title}</span></a>
                            ) : (
                                <a href={url} className="sp_large">{title}</a>
                            )
                        }
                        <ul className="group">
                            {
                                navSubItems ? 
                                    <> {
                                    navSubItems.map((navSubItem: NavSubItem, index: number) => {
                                        return (
                                            <li key={index}>
                                                <a href={navSubItem.url} dangerouslySetInnerHTML={{__html: navSubItem.title}} />
                                            </li>
                                        );
                                    })
                                } </> : <></>
                            }
                        </ul>
                        {element === "header" ? <span className="js-closeBtn pc_large"></span> : <></>}
                    </li>
                </ul>
            </li>
        </>
    )
}

export default NavGroup;
